import { message } from "antd";
import axios from "axios";
import {
  setAddLeadershipModal,
  setTotalLeadership,
  setLeadershipArray,
} from "../../actions/LeadershipActions";

const accessToken = JSON.parse(localStorage.getItem("userData"));

export const createLeadership = async (dispatch, values, image) => {
  const payload = {
    name: values.name,
    designation: values.designation,
    color: values.color,
    linkedin_url: values.linkedIn_URL,
    status: values.status,
    file: image,
    calendly_url: "",
  };

  const formParams = new FormData();
  formParams.append("name", payload?.name);
  formParams.append("designation", payload?.designation);
  formParams.append("color", payload?.color);
  formParams.append("linkedin_url", payload?.linkedin_url);
  formParams.append("calendly_url", payload?.calendly_url);
  formParams.append("status", payload?.status);
  formParams.append("file", image);

  await axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/leadership/create`,
      formParams,
      {
        headers: {
          "ngrok-skip-browser-warning": true,
          Authorization: "Bearer " + accessToken.access_token,
        },
      }
    )
    .then((data) => {
      dispatch(setAddLeadershipModal(false));
      message.success("Leadership created successfully");
    })

    .catch((error) => {
      const errorMessage = error?.response?.data?.message;
      message.error(`${errorMessage}`);
    });
};

export const AllLeadership = async (dispatch) => {
  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/leadership/all`, {
      headers: {
        "ngrok-skip-browser-warning": true,
        Authorization: "Bearer " + accessToken.access_token,
      },
    })
    .then((data) => {
      dispatch(setLeadershipArray(data?.data?.content));
      dispatch(setTotalLeadership(data?.data?.count));
    })
    .catch((error) => {
      const errorMessage = error?.response?.data?.message;
      message.error(`${errorMessage}`);
    });
};

export const Activate = async (object, flag) => {
  const payload = {
    status: flag ? "inactive" : "active",
  };
  await axios
    .patch(
      `${process.env.REACT_APP_API_URL}/api/leadership/${object?.id}`,
      payload,
      {
        headers: {
          "ngrok-skip-browser-warning": true,
          Authorization: "Bearer " + accessToken.access_token,
        },
      }
    )
    .then((response) => {
      message.success("Leadership Updated Successfully");
    })
    .catch((error) => {
      message.error("Leadership Updated Error");
    });
};
export const UpdateLeadership = async (dispatch, values, id) => {
  const payload = {
    name: values.name,
    designation: values.designation,
    color: values.color,
    linkedIn_URL: values.linkedIn_URL,
    status: values.status,
  };

  await axios
    .patch(`${process.env.REACT_APP_API_URL}/api/leadership/${id}`, payload, {
      headers: {
        "ngrok-skip-browser-warning": true,
        Authorization: "Bearer " + accessToken.access_token,
      },
    })
    .then((response) => {
      dispatch(setAddLeadershipModal(false));
      message.success("Leadership Updated Successfully");
    })
    .catch((error) => {
      const errorMessage = error?.response?.data?.message;
      message.error(`${errorMessage}`);
    });
};

export const deleteLeadership = async (dispatch, id) => {
  await axios
    .delete(`${process.env.REACT_APP_API_URL}/api/leadership/${id}`, {
      headers: {
        "ngrok-skip-browser-warning": true,
        Authorization: "Bearer " + accessToken.access_token,
      },
    })
    .then((response) => {
      message.success("Leadership Deleted Successfully");
    })
    .catch((error) => {
      const errorMessage = error?.response?.data?.message;
      message.error(`${errorMessage}`);
    });
};

export const filter = async (dispatch, string) => {
  await axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/leadership/all?search=${string}`,
      {
        headers: {
          "ngrok-skip-browser-warning": true,
          Authorization: "Bearer " + accessToken.access_token,
        },
      }
    )
    .then((data) => {
      dispatch(setLeadershipArray(data?.data?.content));
      dispatch(setTotalLeadership(data?.data?.count));
    })
    .catch((error) => {
      const errorMessage = error?.response?.data?.message;
      message.error(`${errorMessage}`);
    });
};

import React, { useEffect } from "react";
import { FilterForm } from "../Jobs/FilterForm";
import { Spin, Table } from "antd";
import { Columns } from "./jobColumns";
import { useDispatch, useSelector } from "react-redux";
import { setJobLoading } from "../../actions/JobActions";
import { ActiveJobs } from "../Jobs/service";

const JobsActive = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    getActiveJobs();
  }, []);
  const getActiveJobs = async () => {
    dispatch(setJobLoading(true));
    await ActiveJobs(dispatch);
    dispatch(setJobLoading(false));
  };
  const jobData = useSelector((state) => state.jobState);
  const columns = Columns(dispatch);
  return (
    <div>
      <Spin spinning={jobData?.loading}>
        <FilterForm />
        <Table
          columns={columns}
          dataSource={jobData?.jobs}
          pagination={{
            pageSize: 10,
          }}
          scroll={{ x: 1300, y: 660 }}
        />
      </Spin>
    </div>
  );
};
export default JobsActive;

import { Checkbox, Form, Input, message, Select, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import Button from "../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { setAddRolesModal } from "../../actions/RoleActions";
import { createRole, fetchFeatures, updateRole } from "./service";
import { useMutation, useQuery } from "react-query";
import { permissions } from "../../constants/StaticText";

export const AddRole = ({ refetch }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [newPermissions, setNewPermissions] = useState([]);
  const [deletedPermissions, setDeletedPermissions] = useState([]);
  const state = useSelector((state) => state.rolesState);

  const { mutate, isLoading } = useMutation({
    mutationFn: (values) => createRole(values),
  });

  const { mutate: mutateUpdate, updateLoading } = useMutation({
    mutationFn: (body) => updateRole(body, state?.viewModal?.data?.id),
  });

  const handleSubmit = async (values) => {
    mutate(values, {
      onSuccess: () => {
        refetch();
        dispatch(setAddRolesModal(false));
        message.success("Role Updated successfully");
      },
      onError: (err) => {
        message.error(err?.response?.data?.message);
      },
    });
  };
  const handleEdit = async (values) => {
    const body = {
      name: values.name,
      deletePermissions: deletedPermissions,
      newPermissions: newPermissions,
    };
    mutateUpdate(body, {
      onSuccess: () => {
        refetch();
        dispatch(setAddRolesModal(false));
        message.success("Role Updated successfully");
      },
      onError: (err) => {
        message.error(err?.response?.data?.message);
      },
    });
  };
  const { data, isSuccess } = useQuery({
    queryKey: ["permissions"],
    queryFn: () => {
      return fetchFeatures();
    },
    select: (res) => res?.data?.content,
  });

  useEffect(() => {
    if (isSuccess) {
      const newArray = data?.map((item) => {
        return { label: permissions[item?.feature_name], value: item?.id };
      });
      setList(newArray);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (state?.viewModal?.data?.id) {
      const selectedPermissions = state?.viewModal?.data?.features?.map(
        (item) => item?.id
      );
      form.setFieldsValue({
        name: state?.viewModal?.data?.name,
        feature: selectedPermissions,
      });
    }
  }, [form, state]);

  const onChange = useCallback(
    (value) => {
      if (state?.viewModal?.data?.id) {
        const selectedPermissions = state?.viewModal?.data?.features?.map(
          (item) => item?.id
        );
        const newPer = value?.filter(
          (value) => !selectedPermissions.includes(value)
        );
        const delPer = selectedPermissions?.filter(
          (values) => !value.includes(values)
        );
        setNewPermissions(newPer);
        setDeletedPermissions(delPer);
      }
    },
    [state]
  );

  return (
    <Spin spinning={!!isLoading || !!updateLoading}>
      <div className="flex flex-col items-center justify-center items-center text-xl">
        {!state?.viewModal?.visible && <span>Add Role</span>}
        {state?.viewModal?.visible && <span>Edit Role</span>}

        <Form
          onFinish={state?.viewModal?.visible ? handleEdit : handleSubmit}
          form={form}
          layout="vertical"
          style={{ width: "85%", marginTop: "50px" }}
          initialValues={{
            name: state?.viewModal?.visible ? state?.viewModal?.data?.name : "",
          }}
        >
          <Form.Item
            name="name"
            label="Roll Name"
            rules={[
              {
                required: true,
                message: `Role name is required`,
              },
              {
                pattern: new RegExp(/^[A-Za-z\s]+$/),
                message: `Alphabet Only`,
              },
            ]}
          >
            <Input placeholder="Roll name" />
          </Form.Item>
          <Form.Item
            name="feature"
            label="Permissions"
            rules={[
              {
                required: true,
                message: `Permissions is required`,
              },
            ]}
          >
            <Checkbox.Group options={list} onChange={onChange} />
          </Form.Item>

          <Form.Item>
            <div>
              <Button
                label={state?.viewModal?.visible ? "Update Role" : "Add Role"}
                type="submit"
                htmlType="submit"
              />
            </div>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  );
};

import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { VALIDATE_REQUIRED } from "../../assets/validations/Validations";
import { Img } from "../../components/Img";
import TextInput from "../../components/TextInput/TextInput";
import "./styles.css";
import { Spin, message } from "antd";
import Axios from "../../utils/middleware";
import JoditEditor from "jodit-react";

export const AddBlog = () => {
  const navigate = useNavigate();
  const editor = useRef(null);
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    file: "",
    content: "",
    status: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    title: "",
    file: "",
    content: "",
  });

  const fetchBlog = async () => {
    try {
      const response = await Axios.get(`/api/blog/${state?.id}`);
      return response?.data;
    } catch {}
  };

  useEffect(() => {
    const jobState = async () => {
      if (state?.id) {
        const data = await fetchBlog();
        setFormData({
          title: data?.title,
          content: data?.content,
          file: data?.image,
          status: data?.status,
        });
        setImage(data?.image);
      }
    };
    jobState();
  }, []);

  const submitForm = async () => {
    const validate = checkValidate();

    if (validate) {
      setLoading(true);
      const formParams = new FormData();
      formParams.append("title", formData?.title);
      formParams.append("content", formData?.content);
      formParams.append("status", formData?.status);

      if (formData?.file) {
        formParams.append("file", formData?.file);
      }

      try {
        if (state?.id) {
          await Axios.patch(
            `${process.env.REACT_APP_API_URL}/api/blog/${state?.id}`,
            formParams
          );
        } else {
          await Axios.post(
            `${process.env.REACT_APP_API_URL}/api/blog/create`,
            formParams
          );
        }
        setFormData({
          title: "",
          file: "",
          content: "",
          status: "",
        });
        navigate("/blog-listing");
        setLoading(false);
      } catch (e) {
        setLoading(false);
        message.error(e?.response?.data?.message);
      }
    }
  };
  const addFormValues = (key, value) => {
    setFormData((prevData) => ({ ...prevData, [key]: value }));
  };

  const checkValidate = () => {
    let errorMessage = { ...errorMessages };
    errorMessage.title = VALIDATE_REQUIRED(formData.title);
    errorMessage.file = formData.file ? "" : "Image for Card is required";
    errorMessage.content = VALIDATE_REQUIRED(formData.content);

    setErrorMessages(errorMessage);

    return !(errorMessage.title || errorMessage.content || errorMessage.file);
  };
  return (
    <>
      <div>
        <Spin spinning={loading}>
          <div className="w-full justify-center items-center flex">
            <div className="w-[90%]">
              <TextInput
                value={formData?.title}
                placeHolder="Blog Title *"
                error={errorMessages?.title}
                handleChange={(data) => addFormValues("title", data)}
              />
              <div className="mt-[30px]">Blog Content *</div>
              <JoditEditor
                ref={editor}
                value={formData?.content}
                onChange={(data) => addFormValues("content", data)}
              />
              {errorMessages?.content && (
                <div
                  className="text-right flex ,font-normal"
                  style={{ color: "red", width: "100%", fontSize: 12 }}
                >
                  {" "}
                  {errorMessages.content}{" "}
                </div>
              )}
              <div className="mt-[30px]">Image for Card *</div>
              <div
                style={{
                  width: "100%",
                  flexDirection: "column-reverse",
                  display: "flex",
                  height: 60,
                  marginTop: 30,
                  fontSize: 15,
                }}
              >
                <TextInput
                  error={errorMessages.file}
                  type="file"
                  placeHolder="Image for Card *"
                  handleChange={(data) => addFormValues("file", data)}
                />
                {formData.file && (
                  <div
                    style={{
                      width: "100%",
                      flexDirection: "column-reverse",
                      display: "flex",
                      height: 60,
                      marginTop: 0,
                      fontSize: 15,
                    }}
                  >
                    <div style={{ position: "relative" }}>
                      {image && (
                        <>
                          <Img
                            src={`${process.env?.REACT_APP_S3_BUCKET_URL}${image}`}
                            alt={"Selected Image"}
                            style={{ width: "200px", height: "auto" }}
                          />
                          <button
                            style={{
                              position: "relative",
                              left: 180,
                              bottom: 100,
                              background: "transparent",
                              border: "none",
                              cursor: "pointer",
                              color: "red",
                            }}
                            onClick={() => setImage("")}
                          >
                            X
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div
                style={{
                  width: "100%",
                  flexDirection: "column-reverse",
                  display: "flex",
                  marginTop: 30,
                  fontSize: 15,
                }}
              >
                <div>
                  Status *
                  <label style={{ marginLeft: "18px", paddingRight: "8px" }}>
                    <input
                      type="radio"
                      value="published"
                      checked={formData.status === "published"}
                      onChange={(e) => addFormValues("status", e.target.value)}
                    />
                    &nbsp;Publish
                  </label>
                  <label style={{ marginLeft: "18px", paddingRight: "8px" }}>
                    <input
                      type="radio"
                      value="draft"
                      checked={formData.status === "draft"}
                      onChange={(e) => addFormValues("status", e.target.value)}
                    />
                    &nbsp;Draft
                  </label>
                </div>
              </div>

              {errorMessages?.file && (
                <div
                  className="text-right flex ,font-normal"
                  style={{ color: "red", width: "100%", fontSize: 12 }}
                >
                  {" "}
                  {errorMessages.file}{" "}
                </div>
              )}
              <div
                onClick={() => submitForm(state?.id)}
                className="inputs-margin common-pointer mt-[65px] h-[60px] flex justify-center items-center mb-[55px]"
                style={{ backgroundColor: "black" }}
              >
                <div className="font-normal font-spacemono not-italic text-base text-left text-white_A700">
                  Submit
                </div>
                <div className="mt-[4px]">
                  <Img
                    src="images/img_arrowforward_white_a700_14x14.svg"
                    className="mt-0.5 mb-[5px] ml-2.5"
                    alt="arrow-forward"
                  />
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </>
  );
};

export default AddBlog;

import React, { useState } from "react";
import Button from "../../components/Button";
import { Col, Form, Input, Modal, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddBlogsModal,
  setBlogLoading,
  setBlogsViewMOdal,
  setEditBlogModal,
} from "../../actions/BlogActions";
import { AllBlogs, filter } from "./service";
import AddBlog from "./AddBlog";
export const FilterForm = () => {
  const [form] = Form.useForm();
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const blogState = useSelector((state) => state?.blogState);
  const handleCloseAddBlog = () => {
    if (blogState?.viewModal?.visible) {
      dispatch(setAddBlogsModal(false));
      dispatch(setEditBlogModal(false));
      dispatch(setBlogsViewMOdal({ visible: false, data: {} }));
    } else {
      dispatch(setAddBlogsModal(false));
      dispatch(setEditBlogModal(false));
    }
  };
  const handleSearch = async (values) => {
    dispatch(setBlogLoading(true));
    await filter(dispatch, values?.search);
    dispatch(setBlogLoading(false));
  };
  const handleReset = async () => {
    setSearch("");
    form.resetFields(["search"]);
    dispatch(setBlogLoading(true));
    await AllBlogs(dispatch);
    dispatch(setBlogLoading(false));
  };

  return (
    <>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
          }}
        >
          <Form onFinish={handleSearch} form={form} style={{ display: "flex" }}>
            <Form.Item
              name="search"
              rules={[
                {
                  required: true,
                  message: "required field for search",
                },
              ]}
            >
              <Input
                placeholder="Search"
                style={{
                  width: "300px",
                  marginRight: "50px",
                }}
                bordered={true}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <div onClick={handleSearch} type="submit">
                <Button label="Search" />
              </div>
            </Form.Item>
          </Form>
          {search && (
            <div
              onClick={handleReset}
              style={{ marginLeft: "10px" }}
              type={"submit"}
            >
              <Button label="Reset" />
            </div>
          )}
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        ></Col>
      </Row>
      <div> </div>
      <Modal
        open={blogState?.addBlogModal}
        onCancel={handleCloseAddBlog}
        footer={null}
        destroyOnClose={true}
      >
        <AddBlog />
      </Modal>
    </>
  );
};

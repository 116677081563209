// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/** @format */

.button-hover:hover {
  background-color: #001529;
  cursor: pointer;
  color: white;
}

.remove-paddings {
  justify-content: center;
  align-items: center;
  padding: 12px 24px 12px 24px;
  border: solid 1px #001529;
  background-color: white;
}

@media (min-width: 900px) and (max-width: 920px) {
  .remove-paddings {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #001529;
    background-color: white;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Button/styles.css"],"names":[],"mappings":"AAAA,aAAa;;AAEb;EACE,yBAAyB;EACzB,eAAe;EACf,YAAY;AACd;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,4BAA4B;EAC5B,yBAAyB;EACzB,uBAAuB;AACzB;;AAEA;EACE;IACE,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,yBAAyB;IACzB,uBAAuB;EACzB;AACF","sourcesContent":["/** @format */\n\n.button-hover:hover {\n  background-color: #001529;\n  cursor: pointer;\n  color: white;\n}\n\n.remove-paddings {\n  justify-content: center;\n  align-items: center;\n  padding: 12px 24px 12px 24px;\n  border: solid 1px #001529;\n  background-color: white;\n}\n\n@media (min-width: 900px) and (max-width: 920px) {\n  .remove-paddings {\n    padding: 10px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border: solid 1px #001529;\n    background-color: white;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import { Tooltip, Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  setEditTicketModal,
  setTicketLoading,
} from "../../actions/TicketsAction";
import { deleteTicket, AllTickets, extractDate } from "./service";
export const Columns = (dispatch) => {
  return [
    {
      title: "Id",
      key: "1",
      width: 150,
      render: (_, object) => object?.id,
    },
    {
      key: "2",
      title: "Client Name",
      width: 150,
      render: (_, object) => object?.client_name,
    },
    {
      key: "3",
      title: "Company Name",
      width: 150,
      render: (_, object) => object?.company_name,
    },
    {
      key: "4",
      title: "Email",
      width: 200,
      render: (_, object) => object?.email,
    },
    {
      key: "4",
      title: "Phone Number",
      width: 150,
      render: (_, object) => object?.phone_number,
    },
    {
      key: "4",
      title: "Type",
      width: 150,
      render: (_, object) => object?.ticket_type,
    },
    {
      key: "4",
      title: "Status",
      width: 150,
      render: (_, object) => object?.status,
    },

    {
      key: "4",
      title: "Created At",
      width: 150,
      render: (_, object) => extractDate(object?.created_at),
    },
    {
      key: "4",
      title: "Updated by",
      width: 150,
      render: (_, object) => object?.updated_by?.name,
    },

    {
      fixed: "right",
      width: 150,
      title: "Actions",
      align: "center",
      render: (_, object, index) => (
        <>
          <Tooltip title="Detail">
            <EditOutlined
              style={{
                color: "blue",
                cursor: "pointer",
                fontSize: "20px",
                paddingTop: "5px",
              }}
              onClick={() => {
                dispatch(setTicketLoading(true));
                dispatch(setEditTicketModal({ visible: true, data: object }));
                dispatch(setTicketLoading(false));
              }}
            />
          </Tooltip>
          &nbsp; &nbsp;
          <Tooltip title="Delete Ticket">
            <Popconfirm
              style={{ zIndex: 10001 }}
              title="Are you sure to delete this Ticket?"
              onConfirm={async () => {
                dispatch(setTicketLoading(true));
                await deleteTicket(object?.id);
                await AllTickets(dispatch);
                dispatch(setTicketLoading(false));
              }}
              okText="Yes"
              okType="primary"
              cancelText="No"
            >
              <DeleteOutlined
                style={{
                  color: "red",
                  cursor: "pointer",
                  fontSize: "20px",
                  paddingTop: "5px",
                }}
              />
            </Popconfirm>
          </Tooltip>
          &nbsp; &nbsp;
        </>
      ),
      key: "2",
    },
  ];
};

import { Spin, Table } from "antd";
import React, { useEffect } from "react";
import { Columns } from "./Columns";
import { useDispatch, useSelector } from "react-redux";
import { AllBlogs } from "./service";
import { setBlogLoading } from "../../actions/BlogActions";
import { FilterForm } from "./FilterForm";
import { useNavigate } from "react-router-dom";

const Blogs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getAllBlogs();
  }, []);
  const getAllBlogs = async () => {
    dispatch(setBlogLoading(true));
    await AllBlogs(dispatch);
    dispatch(setBlogLoading(false));
  };
  const BlogData = useSelector((state) => state.blogState);
  const columns = Columns(dispatch, navigate);
  return (
    <>
      <div>
        <Spin spinning={BlogData.loading}>
          <FilterForm />
          <Table
            columns={columns}
            dataSource={BlogData?.blog}
            pagination={{
              pageSize: 10,
            }}
            scroll={{ x: 1300, y: 660 }}
          />
        </Spin>
      </div>
    </>
  );
};

export default Blogs;

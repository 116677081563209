import * as actions from "./ActionTypes";

export const setApplicationArray=(data)=>{
    return {
    type:actions.SET_APPLICATION_ARRAY,
    data
}}


export const setEditApplicationModal=(data)=>{
    return{
    type:actions.SET_EDIT_APPLICATION_MODAL,
    data
}}

export const setApplicationLoading=(data)=>{
    return{
    type:actions.SET_APPLICATION_LOADING,
    data
}}
export const setApplicationView=(data)=>{
    return{
        type:actions.SET_APPLICATION_VIEW,
        data
    }
}

import * as actions from "./ActionTypes";

export const setBlogsArray=(data)=>{
    return  {
        type:actions.SET_BLOG_ARRAY,
        data
    }}

export const setBlogsSearch=(data)=>({
    type:actions.SET_BLOG_SEARCH,
    data
})

export const setBlogsViewMOdal=(data)=>({
    type:actions.SET_BLOG_VIEW_MODAL,
    data
})

export const setTotalBlogs=(data)=>({
    type:actions.SET_TOTAL_BLOG,
    data
})

export const setAddBlogsModal=(data)=>({
    type:actions.SET_ADD_BLOG_MODAL,
    data
})

export const setBlogLoading=(data)=>({
    type:actions.SET_BLOG_LOADING,
    data
})

export const setEditBlogModal =(data) =>({
    type:actions.SET_EDIT_BLOG_MODAL,
    data
})

export const setEditBlogData =(data) =>({
    type:actions.SET_EDIT_BLOG_DATA,
    data
})




import React from "react";
import { Tooltip, Popconfirm, Switch, Checkbox } from "antd";
import { DeleteOutlined, EyeOutlined,EditOutlined } from "@ant-design/icons";
import { setAddPicturesModal,setPicturesUpdateModal, setPicturesLoading, setPicturesViewMOdal, setPicturesUpdateModalView } from "../../actions/PictureActions";
import { AllPictures, deletePictures } from "./service";
export const Columns = (dispatch) => {


  return [
    {
      title: "Id",
      key: "1",
      width: 150,
      render: (_, object) => object?.id,
    },
    {
      key: "2",
      title: "Index",
      width: 150,
      render: (_, object) => object?.index,
    },
    {
      key: "3",
      title: "Picture",
      width: 150,
      render: (_, object) => object?.url,
    },
    {
      key: "4",
      title: "BackgroundPicture",
      width: 150,
      render: (_, object) => object?.backgroundImage,
    },
    {
      key: "5",
      title: "Heading",
      width: 150,
      render: (_, object) => object?.heading,
    },
    {
      key: "6",
      title: "Detail",
      width: 150,
      render: (_, object) => object?.detail,
    },
    {
      fixed: "right",
      width: 150,
      title: "Actions",
      align: "center",
      render: (_, object, index) => {
        return <>
          <Tooltip title="Detail">
    
            <EyeOutlined
            style={{
                color: 'blue',
                cursor: 'pointer',
                fontSize: '20px',
                paddingTop: '5px',
            }}
            onClick={() => {
                dispatch(setPicturesViewMOdal({ visible: true, data: object?.backgroundImage }));
              }}
            />
    
         </Tooltip>
         &nbsp; &nbsp;
          <Tooltip title="Edit Picture">
            <EditOutlined
              style={{
                color: "blue",
                cursor: "pointer",
                fontSize: "20px",
                paddingTop: "5px",
              }}
              onClick={() => {
                
                dispatch(setPicturesUpdateModal(true));
                dispatch(setPicturesUpdateModalView({ visible: true, data: object?.id}));

              }}
            />
          </Tooltip>
          &nbsp; &nbsp;
          <Tooltip title="Delete Picture">
            <Popconfirm
              style={{ zIndex: 10001 }}
              title="Are you sure to delete this Picture?"
              onConfirm={async () => {
                dispatch(setPicturesLoading(true));
                await deletePictures(dispatch, object?.id);
                await AllPictures(dispatch);
                dispatch(setPicturesLoading(false));
              }}
              okText="Yes"
              okType="primary"
              cancelText="No"
            >
              <DeleteOutlined
                style={{
                  color: "red",
                  cursor: "pointer",
                  fontSize: "20px",
                  paddingTop: "5px",
                }}
              />
            </Popconfirm>
          </Tooltip>
          &nbsp; &nbsp;
        </>
      },
      key: "7",
    },
  ];
};

import { Form, Input, message, Select } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import Button from "../../components/Button";
import { UpdateUser, createUser, fetchRoles } from "./service";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import { useMutation, useQuery } from "react-query";
import { setAddUserModal } from "../../actions/UserActions";

export const AddUserForm = ({ refetch }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [roleOptions, setRoleOptions] = useState([]);
  const [delRole, setDelRole] = useState([]);
  const [newRole, setNewRole] = useState([]);
  const state = useSelector((state) => state.userState);

  const { mutate, isLoading } = useMutation({
    mutationFn: (values) => createUser(values),
  });

  const { mutate: mutateUpdate, updateLoading } = useMutation({
    mutationFn: (payload) => UpdateUser(payload, state?.viewModal?.data?.id),
  });

  const { data: roles, isSuccess } = useQuery({
    queryKey: ["roles"],
    queryFn: () => fetchRoles(),
    select: (res) => res?.data,
  });

  const handleSubmit = async (values) => {
    mutate(values, {
      onSuccess: () => {
        refetch();
        dispatch(setAddUserModal(false));
        message.success("User Created successfully");
      },
      onError: (err) => {
        message.error(err?.response?.data?.message);
      },
    });
  };
  const handleEdit = async (values) => {
    const payload = {
      email: values.username,
      name: values.name,
      newRoles: newRole,
      deleteRoles: delRole,
    };
    mutateUpdate(payload, {
      onSuccess: () => {
        refetch();
        dispatch(setAddUserModal(false));
        message.success("User Updated successfully");
      },
      onError: (err) => {
        message.error(err?.response?.data?.message);
      },
    });
  };
  useEffect(() => {
    if (isSuccess) {
      const arr = roles?.map((role) => {
        return {
          label: role?.name,
          value: role?.id,
        };
      });
      setRoleOptions(arr);
    }
  }, [isSuccess, roles]);

  useEffect(() => {
    if (state?.viewModal?.data) {
      form?.setFieldsValue({
        name: state?.viewModal?.data?.name,
        username: state?.viewModal?.data?.email,
        roles: state?.viewModal?.data?.role?.id,
      });
    } else {
      form.resetFields();
    }

    return () => form.resetFields();
  }, [state, form]);

  const onChange = useCallback(
    (data) => {
      const value = [data];
      if (state?.viewModal?.data?.role?.id) {
        const selectedRole = [state?.viewModal?.data?.role?.id];
        const newPer = value?.filter((value) => !selectedRole.includes(value));
        const delPer = selectedRole?.filter(
          (values) => !value.includes(values)
        );
        setNewRole(newPer);
        setDelRole(delPer);
      }
    },
    [state]
  );

  return (
    <div className="flex flex-col items-center justify-center items-center text-xl">
      {!state?.viewModal?.visible && <span>Add New User</span>}
      {state?.viewModal?.visible && <span>Edit User</span>}

      <Form
        onFinish={state?.viewModal?.visible ? handleEdit : handleSubmit}
        form={form}
        layout="vertical"
        style={{ width: "85%", marginTop: "50px" }}
        initialValues={{
          name: state?.viewModal?.visible ? state?.viewModal?.data?.name : "",
          username: state?.viewModal?.visible
            ? state?.viewModal?.data?.email
            : "",
          role: state?.viewModal?.visible
            ? state?.viewModal?.data?.role?.id
            : "",
        }}
      >
        <Form.Item
          name="name"
          label="Full Name"
          rules={[
            {
              required: true,
              message: `Name is required`,
            },
            {
              pattern: new RegExp(/^[A-Za-z\s]+$/),
              message: `Alphabet Only`,
            },
          ]}
        >
          <Input placeholder="FULL NAME" />
        </Form.Item>
        <Form.Item
          name="username"
          label="Email"
          rules={[
            {
              required: true,
              message: `Username is required`,
            },
          ]}
        >
          <Input placeholder="Username" />
        </Form.Item>

        <Form.Item
          name="roles"
          label="Role"
          rules={[
            {
              required: true,
              message: `Role is required`,
            },
          ]}
        >
          <Select
            placeholder="Select any role"
            options={roleOptions}
            onChange={onChange}
          />
        </Form.Item>

        <Form.Item>
          <div>
            <Button
              label={state?.viewModal?.visible ? "Update User" : "Create User"}
              type="submit"
              htmlType="submit"
              loading={!!isLoading || !!updateLoading}
            />
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

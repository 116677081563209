import * as actions from "./ActionTypes";

export const setPicturesArray=(data)=>{
    return  {
        type:actions.SET_PICTURES_ARRAY,
        data
    }}



export const setPicturesViewMOdal=(data)=>({
    type:actions.SET_PICTURES_VIEW_MODAL,
    data
})

export const setTotalPictures=(data)=>({
    type:actions.SET_TOTAL_PICTURES,
    data
})

export const setAddPicturesModal=(data)=>({
    type:actions.SET_ADD_PICTURES_MODAL,
    data
})


export const setPicturesUpdateModal=(data)=>({
    type:actions.SET_ADD_PICTURES_UPDATE_MODAL,
    data
})

export const setPicturesUpdateModalView=(data)=>({
    type:actions.SET_ADD_PICTURES_UPDATE_MODAL_VIEW,
    data
})

export const setPicturesLoading=(data)=>({
    type:actions.SET_PICTURES_LOADING,
    data
})




import { Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { Columns } from "./Columns";
import { useDispatch, useSelector } from "react-redux";
import { AllLeadership } from "./service";
import { setLeadershipLoading } from "../../actions/LeadershipActions";
import { FilterForm } from "./FilterForm";

const Leadership = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    getAllLeadership();
  }, []);
  const getAllLeadership = async () => {
    dispatch(setLeadershipLoading(true));
    await AllLeadership(dispatch);
    dispatch(setLeadershipLoading(false));
  };
  const LeadershipData = useSelector((state) => state.leadershipState);
  const columns = Columns(dispatch);
  return (
    <>
      <div>
        <Spin spinning={LeadershipData.loading}>
          <FilterForm />
          <Table
            columns={columns}
            dataSource={LeadershipData?.leadership}
            pagination={{
              pageSize: 10,
            }}
            scroll={{ x: 1300, y: 660 }}
          />
        </Spin>
      </div>
    </>
  );
};

export default Leadership;

import axios from "axios";

const userData = localStorage.getItem("userData");
let data = JSON.parse(userData);
const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: "Bearer " + data?.access_token,
    "ngrok-skip-browser-warning": true,
  },
});

export default Axios;

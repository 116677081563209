import React, { useState } from "react";
import Button from "../../components/Button";
import { Col, Form, Modal, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AddRole } from "./AddRole";
import { filter } from "./service";
import { setAddRolesModal, setRolesViewMOdal } from "../../actions/RoleActions";
import useProfileInfo from "../../hooks/UseProfile";
import { permissions_list } from "../../constants/StaticText";
export const FilterForm = ({ refetch }) => {
  const [form] = Form.useForm();
  const { userPermissions } = useProfileInfo();

  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const roleState = useSelector((state) => state?.rolesState);
  const handleAddRole = () => {
    dispatch(setAddRolesModal(true));
  };
  const handleCloseRole = () => {
    if (roleState?.viewModal?.visible) {
      dispatch(setAddRolesModal(false));
      dispatch(setRolesViewMOdal({ visible: false, data: {} }));
    } else {
      dispatch(setAddRolesModal(false));
    }
  };
  const handleSearch = async (values) => {
    // dispatch(setUserLoading(true))
    await filter(dispatch, values?.search);
    // dispatch(setUserLoading(false))
  };
  const handleReset = async () => {
    setSearch("");
    form.resetFields(["search"]);
    // dispatch(setUserLoading(true))
    // await AllUsers(dispatch);
    // dispatch(setUserLoading(false))
  };

  return (
    <>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
          }}
        >
          <Form onFinish={handleSearch} form={form} style={{ display: "flex" }}>
            <Form.Item>
              <div type="submit"></div>
            </Form.Item>
          </Form>
          {search && (
            <div onClick={handleReset} style={{ marginLeft: "10px" }}>
              <Button label="Reset" />
            </div>
          )}
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {userPermissions?.includes(permissions_list["CREATE_ROLES"]) && (
            <div onClick={handleAddRole}>
              <Button label="Add Roles" />
            </div>
          )}
        </Col>
      </Row>
      <Modal
        open={roleState?.addRolesModal}
        onCancel={handleCloseRole}
        footer={null}
        destroyOnClose={true}
      >
        <AddRole refetch={refetch} />
      </Modal>
    </>
  );
};

import { message } from "antd";
import axios from "axios";
import {
  setTotalUsers,
  setUserArray,
  setUserRoles,
} from "../../actions/UserActions";
import {
  setAddRolesModal,
  setFeaturesArray,
  setRolesFeature,
} from "../../actions/RoleActions";
import Axios from "../../utils/middleware";
import { api_base_url } from "../../constants/constant";
const accessToken = JSON.parse(localStorage.getItem("userData"));

export const createRole = async (values) => {
  const payloadCreate = {
    name: values?.name,
    features: values.feature,
  };
  return Axios.post(`${api_base_url}/api/roles/assign-feature`, payloadCreate);
};

export const updateRole = async (body, id) => {
  return Axios.patch(`${api_base_url}/api/roles/${id}`, body);
};

export const fetchFeatures = () => {
  return Axios.get(`${process.env.REACT_APP_API_URL}/api/features/all`);
  // .then((response) => {
  //   const features = response?.data?.content
  //     .filter(
  //       (feature) =>
  //         ![
  //           "auth",
  //           "users",
  //           "permissions",
  //           "roles",
  //           "role-permissions",
  //           "user-role-permissions",
  //         ].includes(feature.feature_name)
  //     )
  //     .map((feature) => ({ id: feature.id, name: feature.feature_name }));
  //   dispatch(setFeaturesArray(features));
  // })
  // .catch((error) => {
  //   const errorMessage = error?.response?.data?.message;
  //   message.error(`${errorMessage}`);
  // });
};

export const fetchRolesAndFeatures = async (dispatch) => {
  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/roles/rolesByFeatures`, {
      headers: {
        "ngrok-skip-browser-warning": true,
        Authorization: "Bearer " + accessToken.access_token,
      },
    })
    .then((response) => {
      const roles = response?.data.response?.map((role) => ({
        id: role.id,
        name: role.name,
        features: role.features.map((feature) => ({
          id: feature.id,
          feature_name: feature.feature_name,
        })),
      }));

      dispatch(setRolesFeature(roles));
    })
    .catch((error) => {
      const errorMessage = error?.response?.data?.message;
      message.error(`${errorMessage}`);
    });
};

export const fetchRoles = () => {
  return Axios.get(`${process.env.REACT_APP_API_URL}/api/roles`);
};

export const deleteRole = async (dispatch, id) => {
  await axios
    .delete(`${process.env.REACT_APP_API_URL}/api/roles/${id}`, {
      headers: {
        "ngrok-skip-browser-warning": true,
        Authorization: "Bearer " + accessToken.access_token,
      },
    })
    .then((response) => {
      message.success("Role Deleted Successfully");
    })
    .catch((error) => {
      const errorMessage = error?.response?.data?.message;
      message.error(`${errorMessage}`);
    });
};

export const filter = async (dispatch, string) => {
  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/users/all?search=${string}`, {
      headers: {
        "ngrok-skip-browser-warning": true,
        Authorization: "Bearer " + accessToken.access_token,
      },
    })
    .then((data) => {
      dispatch(setUserArray(data?.data?.users));
      dispatch(setTotalUsers(data?.data?.count));
    })
    .catch((error) => {
      const errorMessage = error?.response?.data?.message;
      message.error(`${errorMessage}`);
    });
};

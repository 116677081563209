import * as actions from "./ActionTypes";

export const setUserArray=(data)=>{
    return {
    type:actions.SET_USERS_ARRAY,
    data
}}



export const setUserPermissions=(data)=>{
    return {
    type:actions.SET_USER_PERMISSIONS,
    data
}}

export const setUserRoles=(data)=>{
    return {
    type:actions.SET_USER_ROLES,
    data
}}

export const setUserFeatures=(data)=>{
    return {
    type:actions.SET_USER_FEATUREs,
    data
}}

export const setUserSearch=(data)=>({
    type:actions.SET_USER_SEARCH,
    data
})

export const setUserViewMOdal=(data)=>({
    type:actions.SET_USER_VIEW_MODAL,
    data
})

export const setTotalUsers=(data)=>({
    type:actions.SET_TOTAL_USERS,
    data
})

export const setAddUserModal=(data)=>{
    return{
    type:actions.SET_ADD_USER_MODAL,
    data
}}

export const setUserLoading=(data)=>{
    return{
    type:actions.SET_USER_LOADING,
    data
}}




/** @format */

import React from "react";
import "./styles.css";

export default function TextInput({ placeHolder, handleChange, error,type,value }) {
  return (
    <>
      <input
        className=' w-[100%] h-[50px] my-input font-spacemono '
        style={{
          borderBottomColor: "black",
          borderBottomWidth: "1px",
          color: "black",
        }}
        value={value}
        onChange={(event) => handleChange(type ? event.target.files[0] : event.target.value)}
        placeholder={placeHolder}
        type={type&&"file"}
        accept="image/png,image/jpg,image/jpeg,image/webp"
      />
      <div className='text-right flex ,font-normal' style={{ color: "red", width: "100%",fontSize:12 }}>
        {error}
      </div>
    </>
  );
}

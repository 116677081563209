import * as actions from "../actions/ActionTypes";


const JobsReducers = (
    state={
        jobs:[],
        isSearch:false,
        viewModal:{visible:false,data:{}},
        totalJobs:0,
        addModal:false,
        loading:false
    }
    , action) =>{
        switch (action.type) {
            case actions.SET_JOB_ARRAY:
              return Object.assign({}, state, {
                jobs:action.data
              });
            case actions.SET_JOB_SEARCH:
                return Object.assign({}, state, {
                  isSearch:action.data
                });
            case actions.SET_JOB_VIEW_MODAL:
                return Object.assign({}, state, {
                      viewModal:action.data
                    });
            case actions.SET_TOTAL_JOB:
                return Object.assign({}, state, {
                totalJobs:action.data
              });
              case actions.SET_ADD_JOB_MODAL:
                return Object.assign({}, state, {
                addModal:action.data
              });

              case actions.SET_JOB_LOADING:
                return Object.assign({}, state, {
                loading:action.data
              });

        
              default:
                return state;
            }
}
export default JobsReducers;
import React from "react";
import { Tooltip, Popconfirm, Checkbox } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  setAddDeveloperModal,
  setDeveloperLoading,
  setDeveloperViewMOdal,
} from "../../actions/DeveloperActions";
import { AllDeveloper, deleteDeveloper, Activate } from "./service";
export const Columns = (dispatch) => {
  const handleActivateDev = async (object) => {
    const flag = object?.status === "active" ? true : false;
    dispatch(setDeveloperLoading(true));
    await Activate(object, flag);
    await AllDeveloper(dispatch);
    dispatch(setDeveloperLoading(false));
  };
  return [
    {
      title: "Id",
      key: "1",
      width: 150,
      render: (_, object) => object?.id,
    },
    {
      key: "2",
      title: "Name",
      width: 150,
      render: (_, object) => object?.name,
    },
    {
      key: "3",
      title: "Designation",
      width: 150,
      render: (_, object) => object?.designation,
    },
    {
      key: "4",
      title: "Color",
      width: 150,
      render: (_, object) => object?.color,
    },
    {
      key: "6",
      title: "status",
      width: 150,
      render: (_, object) => (
        <Popconfirm
          okText="Yes"
          cancelText="No"
          title={
            object?.status === "inactive"
              ? "Do You Want to Activate this Developer"
              : "Do You Want to Deactivate this Developer"
          }
          onConfirm={() => handleActivateDev(object)}
        >
          <Checkbox checked={object?.status === "inactive" ? false : true} />
        </Popconfirm>
      ),
    },
    {
      fixed: "right",
      width: 150,
      title: "Actions",
      align: "center",
      render: (_, object, index) => (
        <>
          <Tooltip title="Detail">
            <EditOutlined
              style={{
                color: "blue",
                cursor: "pointer",
                fontSize: "20px",
                paddingTop: "5px",
              }}
              onClick={() => {
                dispatch(
                  setDeveloperViewMOdal({ visible: true, data: object })
                );
                dispatch(setAddDeveloperModal(true));
              }}
            />
          </Tooltip>
          &nbsp; &nbsp;
          <Tooltip title="Delete Developer">
            <Popconfirm
              style={{ zIndex: 10001 }}
              title="Are you sure to delete this Developer?"
              onConfirm={async () => {
                dispatch(setDeveloperLoading(true));
                await deleteDeveloper(dispatch, object?.id);
                await AllDeveloper(dispatch);
                dispatch(setDeveloperLoading(false));
              }}
              okText="Yes"
              okType="primary"
              cancelText="No"
            >
              <DeleteOutlined
                style={{
                  color: "red",
                  cursor: "pointer",
                  fontSize: "20px",
                  paddingTop: "5px",
                }}
              />
            </Popconfirm>
          </Tooltip>
          &nbsp; &nbsp;
        </>
      ),
      key: "2",
    },
  ];
};

import React, { useEffect } from 'react'
import { FilterForm } from '../Blogs/FilterForm'
import { Spin, Table } from 'antd'
import { Columns } from './blogColumns'
import { useDispatch, useSelector } from 'react-redux'
import { setBlogLoading } from '../../actions/BlogActions'
import { ActiveBlogs } from '../Blogs/service'
const BlogActive = () => {
    const dispatch =useDispatch()
    useEffect(() =>{
        getActiveBlogs();
    },[])
    const getActiveBlogs=async()=>{
        dispatch(setBlogLoading(true))
        await ActiveBlogs(dispatch);
        dispatch(setBlogLoading(false))
    }
    const blogData=useSelector((state)=>state.blogState);
    const columns= Columns(dispatch);
    return (
        <div>
            <Spin
                spinning={blogData?.loading}
            >
                <FilterForm/>
                <Table
                    columns={columns}
                    dataSource={blogData?.blog}
                    pagination={{
                        pageSize:10
                    }}
                    scroll={{ x: 1300, y: 660 }}

                />
            </Spin>
        </div>
    )
}
export default BlogActive;

import React, { useRef, useState } from "react";
import Button from "../../components/Button";
import { Col, Form, Input, Modal, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddLeadershipModal,
  setLeadershipLoading,
  setLeadershipViewMOdal,
} from "../../actions/LeadershipActions";
import { AddLeadershipForm } from "./AddLeaderForm";
import { AllLeadership, filter } from "./service";
import useProfileInfo from "../../hooks/UseProfile";
import { permissions_list } from "../../constants/StaticText";
export const FilterForm = () => {
  const [form] = Form.useForm();
  const [search, setSearch] = useState("");
  const { userPermissions } = useProfileInfo();
  const dispatch = useDispatch();
  const leadershipState = useSelector((state) => state?.leadershipState);
  const handleAddLeadership = () => {
    dispatch(setAddLeadershipModal(true));
  };
  const handleCloseAddLeadership = () => {
    if (leadershipState?.viewModal?.visible) {
      dispatch(setAddLeadershipModal(false));
      dispatch(setLeadershipViewMOdal({ visible: false, data: {} }));
    } else {
      dispatch(setAddLeadershipModal(false));
    }
  };
  const handleSearch = async (values) => {
    dispatch(setLeadershipLoading(true));
    await filter(dispatch, values?.search);
    dispatch(setLeadershipLoading(false));
  };
  const handleReset = async () => {
    setSearch("");
    form.resetFields(["search"]);
    dispatch(setLeadershipLoading(true));
    await AllLeadership(dispatch);
    dispatch(setLeadershipLoading(false));
  };

  return (
    <>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
          }}
        >
          <Form onFinish={handleSearch} form={form} style={{ display: "flex" }}>
            <Form.Item
              name="search"
              rules={[
                {
                  required: true,
                  message: "required field for search",
                },
              ]}
            >
              <Input
                placeholder="Search"
                style={{
                  width: "300px",
                  marginRight: "50px",
                }}
                bordered={true}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <div type="submit">
                <Button label="Search" />
              </div>
            </Form.Item>
          </Form>
          {search && (
            <div onClick={handleReset} style={{ marginLeft: "10px" }}>
              <Button label="Reset" />
            </div>
          )}
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {userPermissions?.includes(permissions_list["CREATE_LEADERSHIP"]) && (
            <div onClick={handleAddLeadership}>
              <Button label="Add Leadership" />
            </div>
          )}
        </Col>
      </Row>
      <Modal
        open={leadershipState?.addLeadershipModal}
        onCancel={handleCloseAddLeadership}
        footer={null}
        destroyOnClose={true}
      >
        <AddLeadershipForm />
      </Modal>
    </>
  );
};

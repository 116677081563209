import * as actions from "../actions/ActionTypes";

const DeveloperReducer = (
    state={
        developer:[],
        isSearch:false,
        viewModal:{visible:false,data:{}},
        totalDevelopers:0,
        addDeveloperModal:false,
        loading:false
    }
    , action) =>{
    switch (action.type) {
        case actions.SET_DEVELOPER_ARRAY:
            return Object.assign({}, state, {
                developer:action.data
            });
        case actions.SET_DEVELOPER_SEARCH:
            return Object.assign({}, state, {
                isSearch:action.data
            });
        case actions.SET_DEVELOPER_VIEW_MODAL:
            return Object.assign({}, state, {
                viewModal:action.data
            });
        case actions.SET_TOTAL_DEVELOPER:
            return Object.assign({}, state, {
                totalDevelopers:action.data
            });
        case actions.SET_ADD_DEVELOPER_MODAL:
            return Object.assign({}, state, {
                addDeveloperModal:action?.data
            });
        case actions.SET_DEVELOPER_LOADING:
            return Object.assign({}, state, {
                loading:action?.data
            });



        default:
            return state;
    }
}

export default DeveloperReducer;
import axios from'axios';
import { setEditTicketModal, setTicketArray } from '../../actions/TicketsAction';
import {message} from 'antd'
import { setApplicationArray } from '../../actions/ApplicationActions';
const accessToken = JSON.parse(localStorage.getItem("userData"));


export const AllApplications = async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/apply-jobs/all`, {
        headers: {
          "ngrok-skip-browser-warning": true,
          Authorization: "Bearer " + accessToken.access_token,
        },
      })
      .then((data) => {
        dispatch(setApplicationArray(data?.data?.jobs?.content));
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.message
        message.error(`${errorMessage}`);
    
      });
  };


export const UpdateApplication=async(dispatch,values,id)=>{
  const payload = {
    status:values?.status
  };
await axios.patch(`${process.env.REACT_APP_API_URL}/api/apply-jobs/${id}`,
  payload,
  {
    headers:{
        "ngrok-skip-browser-warning": true,
        Authorization: "Bearer " + accessToken.access_token,

    }
  }
)
.then((response)=>{
    dispatch(setEditTicketModal({visible:false,data:{}}));
    message.success("Ticket Updated Successfully")
})
.catch((error)=>{
    const errorMessage = error?.response?.data?.message
    message.error(`${errorMessage}`);
})


}

export const deleteApplication=async (id) => {
  await axios.delete(`${process.env.REACT_APP_API_URL}/api/apply-jobs/${id}`,
  {
    headers:{
        "ngrok-skip-browser-warning": true,
        Authorization: "Bearer " + accessToken.access_token,

    }
  }
)
.then((response)=>{
    message.success("Application Deleted Successfully")
})
.catch((error)=>{
  const errorMessage = error?.response?.data?.message
  message.error(`${errorMessage}`);

})

}

export const filter = async (dispatch,status) => {
  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/apply-jobs/all?search=${status}`, {
      headers: {
        "ngrok-skip-browser-warning": true,
        Authorization: "Bearer " + accessToken.access_token,
      },
    })
    .then((data) => {
      dispatch(setApplicationArray(data?.data?.jobs?.content));

    })
    .catch((error) => {
      message.error(`something went wrong`);
    });
};


export const Search = async (dispatch,value) => {
  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/apply-jobs/filter?status=${value}`, {
      headers: {
        "ngrok-skip-browser-warning": true,
        Authorization: "Bearer " + accessToken.access_token,
      },
    })
    .then((data) => {
      dispatch(setApplicationArray(data?.data?.content));

    })
    .catch((error) => {
      message.error(`something went wrong`);
    });
};

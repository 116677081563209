// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-center {
    text-align: center;
  }
@media screen and (max-width: 899px) {

    .blogs-paddings {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 100px;
    }
}

@media screen and (min-width: 900px) {

    .blogs-paddings {
        padding-left: 137px;
        padding-right: 137px;
        padding-top: 137px;
    }
}`, "",{"version":3,"sources":["webpack://./src/screens/Blogs/styles.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;EACpB;AACF;;IAEI;QACI,kBAAkB;QAClB,mBAAmB;QACnB,kBAAkB;IACtB;AACJ;;AAEA;;IAEI;QACI,mBAAmB;QACnB,oBAAoB;QACpB,kBAAkB;IACtB;AACJ","sourcesContent":[".text-center {\n    text-align: center;\n  }\n@media screen and (max-width: 899px) {\n\n    .blogs-paddings {\n        padding-left: 20px;\n        padding-right: 20px;\n        padding-top: 100px;\n    }\n}\n\n@media screen and (min-width: 900px) {\n\n    .blogs-paddings {\n        padding-left: 137px;\n        padding-right: 137px;\n        padding-top: 137px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

/** @format */

import { isValidPhoneNumber } from "react-phone-number-input";
import {
  emailRegex,
  email_ends_with_dot_err,
  email_max_length_err,
  email_min_length_err,
  email_starts_with_space_err,
  empty_err,
  please_enter_email_address,
  wrong_email_format,
} from "../../constants/StaticText";

export const VALIDATE_EMAIL = (email) => {
  if (!email) {
    return please_enter_email_address;
  }

  // must not start with space
  if (email?.startsWith(" ")) {
    return email_starts_with_space_err;
  }

  // must not end with dot
  if (email?.endsWith(".")) {
    return email_ends_with_dot_err;
  }

  if (!email?.match(emailRegex)) {
    return wrong_email_format;
  }

  if (email?.length < 2) {
    return email_min_length_err;
  }

  if (email?.length > 50) {
    return email_max_length_err;
  }

  return "";
};

export const VALIDATE_REQUIRED = (name) => {
  // must not be empty
  if (!name) {
    return empty_err;
  }
};
export const VALIDATE_REQUIRED_PDF = (file) => {
  // must not be empty
  if (!file?.name) {
    return empty_err;
  }
  if (file?.type !== 'application/pdf') {
    return 'please add resume in pdf formet only';
  }
};
export const VALIDATE_NUMBER = (number) => {
  if (!number) {
    return empty_err;
  }
  if(!isValidPhoneNumber(number)){
return 'please enter a valid number'
  }
};


export const VALIDATE_PASSWORD = (password) => {
  if (!password.length) {
    return empty_err;
  }
    if (password.length < 8) {
      return 'password must be greater then 8 digits';
    }
  
  return '';
}


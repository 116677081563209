import React, { useRef, useState } from 'react'
import Button from "../../components/Button"
import { Col, Form, Input, Modal, Row } from 'antd'
import { useDispatch,useSelector } from 'react-redux'
import { setAddDeveloperModal, setDeveloperLoading, setDeveloperViewMOdal } from '../../actions/DeveloperActions'
import { AddDeveloperForm } from './AddDeveloperForm'
import { AllDeveloper, filter } from './service'
export const FilterForm = () => {
    const [form]=Form.useForm();
    const [search,setSearch]=useState('');
    const dispatch=useDispatch();
    const developerState=useSelector((state)=>state?.developerState);
    const handleAddDeveloper = () =>{
        dispatch(setAddDeveloperModal(true))
    }
    const handleCloseAddDeveloper = () =>{
        if (developerState?.viewModal?.visible){
            dispatch(setAddDeveloperModal(false));
            dispatch(setDeveloperViewMOdal({visible:false,data:{}}))
        }
        else{
            dispatch(setAddDeveloperModal(false))

        }
    }
    const handleSearch = async(values) =>{
        dispatch(setDeveloperLoading(true))
        await filter(dispatch,values?.search);
        dispatch(setDeveloperLoading(false))

    }
    const handleReset=async()=>{
        setSearch('');
        form.resetFields(['search']);
        dispatch(setDeveloperLoading(true))
        await AllDeveloper(dispatch);
        dispatch(setDeveloperLoading(false))


    }

    return (
        <>
            <Row>
                <Col
                    span={12}
                    style={{
                        display: 'flex',
                    }}
                >
                    <Form
                        onFinish={handleSearch}
                        form={form}
                        style={{display: 'flex'}}
                    >
                        <Form.Item
                            name='search'
                            rules={[
                                {
                                    required: true,
                                    message: 'required field for search'
                                }
                            ]}
                        >
                            <Input
                                placeholder='Search'
                                style={{
                                    width:"300px",
                                    marginRight:"50px"
                                }}
                                bordered={true}
                                onChange={(e)=>setSearch(e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item>
                            <div
                                type='submit'
                            >
                                <Button
                                    label='Search'
                                />
                            </div>
                        </Form.Item>
                    </Form>
                    {search &&

                        <div
                            onClick={handleReset}
                            style={{marginLeft:"10px"}}
                        >
                            <Button
                                label='Reset'
                            />
                        </div>
                    }

                </Col>
                <Col
                    span={12}
                    style={{
                        display:"flex",
                        justifyContent:"flex-end"
                    }}
                >
                    <div onClick={handleAddDeveloper}>
                        <Button
                            label='Add Developer'

                        />
                    </div>
                </Col>
            </Row>
            <Modal
                open={developerState?.addDeveloperModal}
                onCancel={handleCloseAddDeveloper}
                footer={null}
                destroyOnClose={true}
            >
                <AddDeveloperForm/>
            </Modal>
        </>
    )
}

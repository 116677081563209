import React from "react";
import { Tooltip, Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  setAddUserModal,
  setUserLoading,
  setUserViewMOdal,
} from "../../actions/UserActions";
import { deleteUser } from "./service";
import useProfileInfo from "../../hooks/UseProfile";
import { permissions_list } from "../../constants/StaticText";
export const Columns = (dispatch, refetch) => {
  const { userPermissions } = useProfileInfo();

  return [
    {
      title: "Id",
      key: "1",
      width: 150,
      render: (_, object) => object?.id,
    },
    {
      key: "2",
      title: "Name",
      width: 150,
      render: (_, object) => object?.name,
    },
    {
      key: "3",
      title: "Username",
      width: 150,
      render: (_, object) => object?.email,
    },
    {
      key: "4",
      title: "Role",
      width: 150,
      render: (_, object) => object?.role?.name,
    },
    {
      fixed: "right",
      width: 150,
      title: "Actions",
      align: "center",
      render: (_, object, index) => (
        <>
          {userPermissions?.includes(permissions_list["UPDATE_USERS"]) && (
            <Tooltip title="Detail">
              <EditOutlined
                style={{
                  color: "blue",
                  cursor: "pointer",
                  fontSize: "20px",
                  paddingTop: "5px",
                }}
                onClick={() => {
                  dispatch(setUserViewMOdal({ visible: true, data: object }));
                  dispatch(setAddUserModal(true));
                }}
              />
            </Tooltip>
          )}
          &nbsp; &nbsp;
          {userPermissions?.includes(permissions_list["DELETE_USERS"]) && (
            <Tooltip title="Delete User">
              <Popconfirm
                style={{ zIndex: 10001 }}
                title="Are you sure to delete this User?"
                onConfirm={async () => {
                  dispatch(setUserLoading(true));
                  await deleteUser(object?.id);
                  refetch();
                  dispatch(setUserLoading(false));
                }}
                okText="Yes"
                okType="primary"
                cancelText="No"
              >
                <DeleteOutlined
                  style={{
                    color: "red",
                    cursor: "pointer",
                    fontSize: "20px",
                    paddingTop: "5px",
                  }}
                />
              </Popconfirm>
            </Tooltip>
          )}
          &nbsp; &nbsp;
        </>
      ),
      key: "2",
    },
  ];
};

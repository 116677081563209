import * as actions from "./ActionTypes";

export const setLeadershipArray=(data)=>{
    return {
        type:actions.SET_LEADERSHIP_ARRAY,
        data
    }}

export const setLeadershipSearch=(data)=>({
    type:actions.SET_LEADERSHIP_SEARCH,
    data
})

export const setLeadershipViewMOdal=(data)=>({
    type:actions.SET_LEADERSHIP_VIEW_MODAL,
    data
})

export const setTotalLeadership=(data)=>({
    type:actions.SET_TOTAL_LEADERSHIP,
    data
})

export const setAddLeadershipModal=(data)=>{
    return{
        type:actions.SET_ADD_LEADERSHIP_MODAL,
        data
    }}

export const setLeadershipLoading=(data)=>{
    return{
        type:actions.SET_LEADERSHIP_LOADING,
        data
    }}




import React from "react";
import JobsActive from "./ActiveJobs";
import BlogActive from "./ActiveBlog";
export const Admin = () => {
  return (
    <>
      <div>
        {/* <div>Active Jobs Count:</div>
                {jobsCount} */}
      </div>
      <div>
        <p>Active Jobs: </p>
        <JobsActive />
      </div>

      <div>
        <p>Active Blogs: </p>
        <BlogActive />
      </div>
    </>
  );
};

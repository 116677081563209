import { message } from "antd";
import axios from "axios";
import Axios from "../../../utils/middleware";
import { api_base_url, base_url } from "../../../constants/constant";
export const fetchRolesAndFeatures = async (accessToken) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/roles/rolesByFeatures`,
      {
        headers: {
          "ngrok-skip-browser-warning": true,
          Authorization: "Bearer " + accessToken,
        },
      }
    );

    const roles = response?.data.response?.map((role) => ({
      id: role.id,
      name: role.name,
      features: role.features.map((feature) => ({
        id: feature.id,
        feature_name: feature.feature_name,
      })),
    }));

    return roles;
  } catch (error) {
    const errorMessage = error?.response?.data?.message;
    message.error(`${errorMessage}`);
    throw error;
  }
};

export const updatePassword = (data) => {
  return axios.patch(`${api_base_url}/api/users/update-password`, data);
};

export const meAuth = () => Axios.get("/api/auth/me");

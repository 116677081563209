import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useProfileInfo = () => {
  const { profileData } = useSelector((state) => state?.userProfile);
  const [userPermissions, setUserPermissions] = useState([]);
  const [profileInfo, setProfileInfo] = useState();

  useEffect(() => {
    if (profileData?.id) {
      if (profileData?.permissions?.length) {
        setUserPermissions(profileData?.permissions);
      }
      setProfileInfo({ ...profileData });
    }
  }, [profileData]);
  return { userPermissions, profileInfo };
};

export default useProfileInfo;

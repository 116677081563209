import axios from "axios";
import {
  setAddJobsModal,
  setJobsArray,
  setTotalJobs,
} from "../../actions/JobActions";
import { message } from "antd";

const accessToken = JSON.parse(localStorage.getItem("userData"));

export const createJob = async (dispatch, values) => {
  const payload = {
    title: values.title,
    description: values.description,
    experience: values.experience,
    location: values.location,
    job_type: values.type,
    status: values.status,
  };

  await axios
    .post(`${process.env.REACT_APP_API_URL}/api/job/create`, payload, {
      headers: {
        "ngrok-skip-browser-warning": true,
        Authorization: "Bearer " + accessToken.access_token,
      },
    })
    .then((data) => {
      dispatch(setAddJobsModal(false));
      message.success("User created successfully");
    })

    .catch((error) => {
      message.error(`something went wrong`);
    });
};

export const AllJobs = async (dispatch) => {
  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/job/all`, {
      headers: {
        "ngrok-skip-browser-warning": true,
        Authorization: "Bearer " + accessToken.access_token,
      },
    })
    .then((data) => {
      dispatch(setJobsArray(data?.data?.jobs));
      dispatch(setTotalJobs(data?.data?.count));
    })
    .catch((error) => {
      message.error(error?.response?.data?.message);
    });
};
export const ActiveJobs = async (dispatch) => {
  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/job/activejobs`, {
      headers: {
        "ngrok-skip-browser-warning": true,
        Authorization: "Bearer " + accessToken.access_token,
      },
    })
    .then((data) => {
      dispatch(setJobsArray(data?.data?.content));
      dispatch(setTotalJobs(data?.data?.count));
    })
    .catch((error) => {
      message.error(`something went wrong`);
    });
};

export const UpdateJob = async (dispatch, values, id) => {
  const payload = {
    title: values.title,
    description: values.description,
    experience: values.experience,
    location: values.location,
    job_type: values.type,
    status: values.status,
  };
  await axios
    .patch(`${process.env.REACT_APP_API_URL}/api/job/${id}`, payload, {
      headers: {
        "ngrok-skip-browser-warning": true,
        Authorization: "Bearer " + accessToken.access_token,
      },
    })
    .then((response) => {
      dispatch(setAddJobsModal(false));
      message.success("User Updated Successfully");
    })
    .catch((error) => {
      message.error("User Updated Error");
    });
};

export const Activate = async (object, flag) => {
  let status = flag ? "draft" : "published";
  const payload = {
    status,
  };

  await axios
    .patch(`${process.env.REACT_APP_API_URL}/api/job/${object?.id}`, payload, {
      headers: {
        "ngrok-skip-browser-warning": true,
        Authorization: "Bearer " + accessToken.access_token,
      },
    })
    .then((response) => {
      message.success("Job Updated Successfully");
    })
    .catch((error) => {
      message.error("Job Updated Error");
    });
};

export const deleteJob = async (id) => {
  await axios
    .delete(`${process.env.REACT_APP_API_URL}/api/job/${id}`, {
      headers: {
        "ngrok-skip-browser-warning": true,
        Authorization: "Bearer " + accessToken.access_token,
      },
    })
    .then((response) => {
      message.success("Job Deleted Successfully");
    })
    .catch((error) => {
      message.error("Job Deleted Error");
    });
};

export const filter = async (dispatch, string) => {
  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/job/all?search=${string}`, {
      headers: {
        "ngrok-skip-browser-warning": true,
        Authorization: "Bearer " + accessToken.access_token,
      },
    })
    .then((data) => {
      dispatch(setJobsArray(data?.data?.jobs));
      dispatch(setTotalJobs(data?.data?.count));
    })
    .catch((error) => {
      message.error(`something went wrong`);
    });
};

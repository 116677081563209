import { Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { Columns } from "./Columns";
import { useDispatch, useSelector } from "react-redux";
import { AllDeveloper } from "./service";
import { setDeveloperLoading } from "../../actions/DeveloperActions";
import { FilterForm } from "./FilterForm";
const Developer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    getAllDeveloper();
  }, []);
  const getAllDeveloper = async () => {
    dispatch(setDeveloperLoading(true));
    await AllDeveloper(dispatch);
    dispatch(setDeveloperLoading(false));
  };
  const DeveloperData = useSelector((state) => state.developerState);
  const columns = Columns(dispatch);
  return (
    <>
      <div>
        <Spin spinning={DeveloperData.loading}>
          <FilterForm />
          <Table
            columns={columns}
            dataSource={DeveloperData?.developer}
            pagination={{
              pageSize: 10,
            }}
            scroll={{ x: 1300, y: 660 }}
          />
        </Spin>
      </div>
    </>
  );
};

export default Developer;

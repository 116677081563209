import * as actions from "../actions/ActionTypes";

const BlogReducer = (
    state={
        blog:[],
        isSearch:false,
        viewModal:{visible:false,data:{}},
        totalBlogs:0,
        addBlogModal:false,
        loading:false
    }
    , action) =>{
    switch (action.type) {
        case actions.SET_BLOG_ARRAY:
            return Object.assign({}, state, {
                blog:action.data
            });
        case actions.SET_BLOG_SEARCH:
            return Object.assign({}, state, {
                isSearch:action.data
            });
        case actions.SET_BLOG_VIEW_MODAL:
            return Object.assign({}, state, {
                viewModal:action.data
            });
        case actions.SET_TOTAL_BLOG:
            return Object.assign({}, state, {
                totalBlogs:action.data
            });
        case actions.SET_ADD_BLOG_MODAL:
            return Object.assign({}, state, {
                addBlogModal:action?.data
            });
        case actions.SET_BLOG_LOADING:
            return Object.assign({}, state, {
                loading:action?.data
            });



        default:
            return state;
    }
}

export default BlogReducer;
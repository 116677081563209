// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-input:hover {
  border-color: red;
}

.ant-select-selector {
  border: 1px solid #6b7280 !important;
  height: 43px !important;
  border-radius: 0px !important;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Users/style.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,oCAAoC;EACpC,uBAAuB;EACvB,6BAA6B;AAC/B","sourcesContent":[".ant-input:hover {\n  border-color: red;\n}\n\n.ant-select-selector {\n  border: 1px solid #6b7280 !important;\n  height: 43px !important;\n  border-radius: 0px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-center {
  text-align: center;
}
@media screen and (max-width: 899px) {
  .blogs-paddings {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 100px;
  }
}

@media screen and (min-width: 900px) {
  .blogs-paddings {
    padding-left: 137px;
    padding-right: 137px;
    padding-top: 137px;
  }
}

.add-line-button {
  background-color: #001529 !important;
  color: white !important;
  height: 50px;
  font-weight: bold !important;
  font-size: 20px;
}

.title_heading {
  margin-bottom: 10px;
}

.input-field-class {
  width: 100% !important;
}
.container-div {
  overflow-y: auto;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Jobs/styles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE;IACE,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,mBAAmB;IACnB,oBAAoB;IACpB,kBAAkB;EACpB;AACF;;AAEA;EACE,oCAAoC;EACpC,uBAAuB;EACvB,YAAY;EACZ,4BAA4B;EAC5B,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;AACxB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".text-center {\n  text-align: center;\n}\n@media screen and (max-width: 899px) {\n  .blogs-paddings {\n    padding-left: 20px;\n    padding-right: 20px;\n    padding-top: 100px;\n  }\n}\n\n@media screen and (min-width: 900px) {\n  .blogs-paddings {\n    padding-left: 137px;\n    padding-right: 137px;\n    padding-top: 137px;\n  }\n}\n\n.add-line-button {\n  background-color: #001529 !important;\n  color: white !important;\n  height: 50px;\n  font-weight: bold !important;\n  font-size: 20px;\n}\n\n.title_heading {\n  margin-bottom: 10px;\n}\n\n.input-field-class {\n  width: 100% !important;\n}\n.container-div {\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Img } from "../../components/Img";
import "./styles.css";
import { Button, Col, Form, Input, Radio, Row, Spin, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  setAddJobsModal,
  setJobLoading,
  setJobsViewMOdal,
} from "../../actions/JobActions";
import "./styles.css";
import Axios from "../../utils/middleware";
export const AddJob = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const fetchjob = async () => {
    try {
      const response = await Axios.get(`/api/job/${state?.id}`);
      return response?.data?.job;
    } catch {}
  };

  useEffect(() => {
    const jobState = async () => {
      if (state?.id) {
        const data = await fetchjob();
        form.setFieldsValue({
          title: data?.title,
          desc: data?.description,
          experience: data?.experience,
          location: data?.location,
          job_type: data?.job_type,
          status: data?.status,
          sub_title: data?.subtitle,
          response: data?.responsibilities?.map((item) => {
            return { newline: item };
          }),
          requirement: data?.requirements?.map((item) => {
            return { newline: item };
          }),
          prerequisite: data?.pre_requisite?.map((item) => {
            return { newline: item };
          }),
        });
      }
    };
    jobState();
  }, []);

  const submitForm = async (formValues) => {
    let responsibilities;
    let requirements;
    let prerequisites;
    if (formValues?.response?.length > 0) {
      responsibilities = formValues?.response?.map((item) => item?.newline);
    } else {
      message.error("Please add any responsibilities");
      return;
    }
    if (formValues?.requirement?.length > 0) {
      requirements = formValues?.requirement?.map((item) => item?.newline);
    } else {
      message.error("Please add any requirements");
      return;
    }
    if (formValues?.prerequisite?.length > 0) {
      prerequisites = formValues?.prerequisite?.map((item) => item?.newline);
    } else {
      message.error("Please add any prerequisite");
      return;
    }

    const formParams = {
      title: formValues?.title,
      subtitle: formValues?.sub_title,
      description: formValues?.desc,
      responsibilities: responsibilities,
      requirements: requirements,
      experience: formValues?.experience,
      job_type: formValues?.job_type,
      status: formValues?.status,
      location: formValues?.location,
      pre_requisite: prerequisites,
    };
    try {
      if (state?.id) {
        await Axios.patch(`/api/job/${state?.id}`, formParams);
      } else {
        await Axios.post(`/api/job/create`, formParams);
      }
      dispatch(setJobLoading(false));
      navigate("/jobs");
      message.success("Job created successfully");
      dispatch(setJobsViewMOdal({ visible: false, data: {} }));
      dispatch(setAddJobsModal(false));
    } catch (err) {
      dispatch(setJobLoading(false));
      message.error(err?.response?.data?.message);
    }
  };
  return (
    <>
      <div className="w-full justify-center items-center flex container-div">
        <div className="w-[90%]">
          <Form form={form} onFinish={submitForm} layout={"vertical"}>
            <Row gutter={[16, 16]}>
              <Col md={24} lg={12}>
                <Form.Item
                  name="title"
                  label="Title"
                  rules={[
                    {
                      required: true,
                      message: "Please enter title !",
                    },
                  ]}
                >
                  <Input placeHolder="Job Title *" />
                </Form.Item>
              </Col>
              <Col md={24} lg={12}>
                <Form.Item
                  name="experience"
                  label="Experience"
                  rules={[
                    {
                      required: true,
                      message: "Please enter experience required !",
                    },
                  ]}
                >
                  <Input placeHolder="Experience *" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col md={24} lg={12}>
                <Form.Item
                  name="sub_title"
                  label="Sub Title"
                  rules={[
                    {
                      required: true,
                      message: "Please enter sub title !",
                    },
                  ]}
                >
                  <Input placeHolder="Sub Title *" />
                </Form.Item>
              </Col>
              <Col md={24} lg={12}>
                <Form.Item
                  name="desc"
                  label="Description"
                  rules={[
                    {
                      required: true,
                      message: "Please enter description!",
                    },
                  ]}
                >
                  <Input placeHolder="Description *" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col md={24} lg={12}>
                <Form.Item
                  name="location"
                  label="Location"
                  rules={[
                    {
                      required: true,
                      message: "Please enter location !",
                    },
                  ]}
                >
                  <Input placeHolder="Location *" />
                </Form.Item>
              </Col>
              <Col md={24} lg={12}>
                <Form.Item
                  name="job_type"
                  label="Job Type"
                  rules={[
                    {
                      required: true,
                      message: "Please enter job type !",
                    },
                  ]}
                >
                  <Input placeHolder="Job Type *" />
                </Form.Item>
              </Col>
            </Row>

            <div className="title_heading">Responsibilities</div>

            <Form.List name="response">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...field }) => (
                    <Row key={key} gutter={[16, 16]}>
                      <Col span={21}>
                        <Form.Item
                          {...field}
                          name={[name, "newline"]}
                          key={[key + "lineItem"]}
                          rules={[
                            {
                              required: true,
                              message: "missing value",
                            },
                          ]}
                        >
                          <Input placeholder="add new line of responsibilities" />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <MinusCircleOutlined
                          style={{ height: 40, fontSize: 25 }}
                          onClick={() => {
                            remove(name);
                          }}
                        />
                      </Col>
                    </Row>
                  ))}

                  <Button className="add-line-button mb-3" onClick={add} block>
                    <span>
                      <PlusOutlined style={{ paddingRight: "10px" }} />
                      Add Responsibility
                    </span>
                  </Button>
                </>
              )}
            </Form.List>
            <div className="title_heading">Requirements</div>
            <Form.List name="requirement">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...field }) => (
                    <Row key={key} gutter={10}>
                      <Col span={21}>
                        <Form.Item
                          {...field}
                          name={[name, "newline"]}
                          key={[key + "lineItem"]}
                          rules={[
                            {
                              required: true,
                              message: "missing value",
                            },
                          ]}
                        >
                          <Input
                            placeholder="add new line of requirement"
                            className="input-field-class"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <MinusCircleOutlined
                          style={{ height: 40, fontSize: 25 }}
                          onClick={() => {
                            remove(name);
                          }}
                        />
                      </Col>
                    </Row>
                  ))}

                  <Button className="add-line-button mb-3" onClick={add} block>
                    <span>
                      <PlusOutlined
                        style={{ paddingRight: "10px", color: "white" }}
                      />
                      Add Requirement
                    </span>
                  </Button>
                </>
              )}
            </Form.List>
            <div className="title_heading">Prerequisite</div>

            <Form.List name="prerequisite">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...field }) => (
                    <Row key={key} gutter={[16, 16]}>
                      <Col span={21}>
                        <Form.Item
                          {...field}
                          name={[name, "newline"]}
                          key={[key + "lineItem"]}
                          rules={[
                            {
                              required: true,
                              message: "missing value",
                            },
                          ]}
                        >
                          <Input placeholder="add new line of prerequisite" />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <MinusCircleOutlined
                          style={{ height: 40, fontSize: 25 }}
                          onClick={() => {
                            remove(name);
                          }}
                        />
                      </Col>
                    </Row>
                  ))}

                  <Button className="add-line-button mb-3" onClick={add} block>
                    <span>
                      <PlusOutlined style={{ paddingRight: "10px" }} />
                      Add Prerequisite
                    </span>
                  </Button>
                </>
              )}
            </Form.List>

            <Form.Item
              name="status"
              label="Status"
              rules={[
                {
                  required: true,
                  message: "select any one",
                },
              ]}
            >
              <Radio.Group>
                <Radio value="published">Publish</Radio>
                <Radio value="draft">Draft</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item>
              <Button
                className="inputs-margin common-pointer mt-[65px] h-[60px] flex justify-center items-center mb-[55px]"
                style={{ backgroundColor: "#001529" }}
                htmlType="submit"
              >
                <div className="font-normal font-spacemono not-italic text-base text-left text-white_A700">
                  Submit
                </div>
                <div className="mt-[4px]">
                  <Img
                    src="images/img_arrowforward_white_a700_14x14.svg"
                    className="mt-0.5 mb-[5px] ml-2.5"
                    alt="arrow-forward"
                  />
                </div>
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddJob;

import * as actions from "./ActionTypes";

export const setDeveloperArray=(data)=>{
    return {
        type:actions.SET_DEVELOPER_ARRAY,
        data
    }}

export const setDeveloperSearch=(data)=>({
    type:actions.SET_DEVELOPER_SEARCH,
    data
})

export const setDeveloperViewMOdal=(data)=>({
    type:actions.SET_DEVELOPER_VIEW_MODAL,
    data
})

export const setTotalDeveloper=(data)=>({
    type:actions.SET_TOTAL_DEVELOPER,
    data
})

export const setAddDeveloperModal=(data)=>{
    return{
        type:actions.SET_ADD_DEVELOPER_MODAL,
        data
    }}

export const setDeveloperLoading=(data)=>{
    return{
        type:actions.SET_DEVELOPER_LOADING,
        data
    }}




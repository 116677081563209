import React from "react";
import { Checkbox } from "antd";
import { extractDate } from "../Tickets/service";

export const Columns = (dispatch) => {
  return [
    {
      title: "Id",
      key: "1",
      width: 150,
      render: (_, object) => object?.id,
    },
    {
      key: "2",
      title: "Title",
      width: 150,
      render: (_, object) => object?.title,
    },
    {
      key: "3",
      title: "Image",
      width: 150,
      render: (_, object) => object?.image,
    },

    {
      key: "5",
      title: "status",
      width: 150,
      render: (_, object) => (
        <Checkbox checked={object?.status === "inactive" ? false : true} />
      ),
    },
    {
      key: "6",
      title: "Created At",
      width: 150,
      render: (_, object) => extractDate(object?.created_at),
    },
    {
      key: "7",
      title: "Updated At",
      width: 150,
      render: (_, object) => extractDate(object?.updated_at),
    },
  ];
};

/** @format */

import React, { useState} from "react";
import "./styles.css";
import { Text } from "../../components/Text";
import { Img } from "../../components/Img";

export default function Button({ Icon, label }) {

  const [hover, sethover] = useState(false)
  return (
    <button onMouseEnter={() => sethover(!hover)} onMouseLeave={() => sethover(!hover)} className='remove-paddings flex button-hover'>
      <Text className='font-medium  text-base text-black_500 text-right'>
        {label}
      </Text>
      {Icon && (
        <div className="items-center"  >
          {hover ? (
            <>
            <Img
              src='images/img_arrowforward_white_a700_14x14.svg'
              className='  ml-2.5'
              alt='arrow-forward'
              height='14'
              width='14'
              />
              </>
          ) : (
            <Img
              src='images/img_arrowforward.webp'
              className=' ml-2.5'
              alt='arrow-forward'
              height='14'
              width='14'
            />
          )}
        </div>

      )}
    </button>
  );
}

import * as actions from "../actions/ActionTypes";


const ApplicationReducers = (
    state={
        applications:[],
        editModal:{visible:false,data:{}},
        loading:false,
        view:{
          visible:false,
          data:{}
        }
    }
    , action) =>{
        switch (action.type) {
            case actions.SET_APPLICATION_ARRAY:
              return Object.assign({}, state, {
                applications:action.data
              });

              case actions.SET_APPLICATION_LOADING:
                return Object.assign({}, state, {
                loading:action.data
              });
              case actions.SET_EDIT_APPLICATION_MODAL:
                return Object.assign({}, state, {
                editModal:action.data
              });
              case actions.SET_APPLICATION_VIEW:
                return Object.assign({}, state, {
                view:action.data
              });


        
              default:
                return state;
            }
}
export default ApplicationReducers;
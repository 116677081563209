import * as actions from "../actions/ActionTypes";

 const RolesReducer = (
    state={
        features:[],
        roleFeatures: [],
        isSearch:false,
        viewModal:{visible:false,data:{}},
        totalFeatures:0,
        addRolesModal:false,
        loading:false
    }
    , action) =>{
        switch (action.type) {
            case actions.SET_FEATURES_ARRAY:
              return Object.assign({}, state, {
                features:action.data
              });

              case actions.SET_ROLES_LOADING:
                return Object.assign({}, state, {
                loading:action?.data
              });

              case actions.SET_TOTAL_FEATURES:
                return Object.assign({}, state, {
                  totalFeatures:action.data
                });
            case actions.SET_ROLES_FEATURE:
                return Object.assign({}, state, {
                    roleFeatures:action.data
                });
            case actions.SET_ADD_ROLES_MODAL:
                return Object.assign({}, state, {
                    addRolesModal:action?.data
                });
            case actions.SET_ROLES_VIEW_MODAL:
                return Object.assign({}, state, {
                    viewModal:action?.data
                });
        
              default:
                return state;
            }
}

export default RolesReducer;
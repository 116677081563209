import axios from "axios";
import {
  setEditTicketModal,
  setTicketArray,
} from "../../actions/TicketsAction";
import { message } from "antd";
const accessToken = JSON.parse(localStorage.getItem("userData"));

export const AllTickets = async (dispatch) => {
  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/tickets/all`, {
      headers: {
        "ngrok-skip-browser-warning": true,
        Authorization: "Bearer " + accessToken.access_token,
      },
    })
    .then((data) => {
      dispatch(setTicketArray(data?.data?.content));
    })
    .catch((error) => {
      const errorMessage = error?.response?.data?.message;
      message.error(`${errorMessage}`);
    });
};

export const UpdateTicket = async (dispatch, values, id) => {
  const payload = {
    status: values?.status,
  };
  await axios
    .patch(`${process.env.REACT_APP_API_URL}/api/tickets/${id}`, payload, {
      headers: {
        "ngrok-skip-browser-warning": true,
        Authorization: "Bearer " + accessToken.access_token,
      },
    })
    .then((response) => {
      dispatch(setEditTicketModal({ visible: false, data: {} }));
      message.success("Ticket Updated Successfully");
    })
    .catch((error) => {
      const errorMessage = error?.response?.data?.message;
      message.error(`${errorMessage}`);
    });
};

export const deleteTicket = async (id) => {
  await axios
    .delete(`${process.env.REACT_APP_API_URL}/api/tickets/${id}`, {
      headers: {
        "ngrok-skip-browser-warning": true,
        Authorization: "Bearer " + accessToken.access_token,
      },
    })
    .then((response) => {
      message.success("Tickets Deleted Successfully");
    })
    .catch((error) => {
      const errorMessage = error?.response?.data?.message;
      message.error(`${errorMessage}`);
    });
};

export const filter = async (dispatch, status) => {
  await axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/tickets/filter?status=${status}`,
      {
        headers: {
          "ngrok-skip-browser-warning": true,
          Authorization: "Bearer " + accessToken.access_token,
        },
      }
    )
    .then((data) => {
      dispatch(setTicketArray(data?.data?.content));
    })
    .catch((error) => {
      message.error(`something went wrong`);
    });
};

export const extractDate = (dateString) => {
  const dateObject = new Date(dateString);
  const options = { year: "numeric", month: "short", day: "numeric" };
  return dateObject.toLocaleDateString("en-US", options);
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.password-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: 100vh !important;
  flex-direction: column;
}

.input-styling {
  width: 400px !important;
  height: 56px !important;
  border: 1px solid black !important;
  border-radius: 0px !important;
}

.submit-button {
  background-color: black !important;
  color: white !important;
  height: 56px !important;
  width: 400px !important;
  border-radius: 0px !important;
  border: none !important;
}
.heading {
  height: 100px !important;
}
.subHeading {
  font-size: 18px;
  font-weight: normal !important;
}
`, "",{"version":3,"sources":["webpack://./src/screens/setPassword/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,wBAAwB;EACxB,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;EACvB,uBAAuB;EACvB,kCAAkC;EAClC,6BAA6B;AAC/B;;AAEA;EACE,kCAAkC;EAClC,uBAAuB;EACvB,uBAAuB;EACvB,uBAAuB;EACvB,6BAA6B;EAC7B,uBAAuB;AACzB;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,eAAe;EACf,8BAA8B;AAChC","sourcesContent":[".password-screen {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100% !important;\n  height: 100vh !important;\n  flex-direction: column;\n}\n\n.input-styling {\n  width: 400px !important;\n  height: 56px !important;\n  border: 1px solid black !important;\n  border-radius: 0px !important;\n}\n\n.submit-button {\n  background-color: black !important;\n  color: white !important;\n  height: 56px !important;\n  width: 400px !important;\n  border-radius: 0px !important;\n  border: none !important;\n}\n.heading {\n  height: 100px !important;\n}\n.subHeading {\n  font-size: 18px;\n  font-weight: normal !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { Spin, Table } from "antd";
import React from "react";
import { Columns } from "./Columns";
import { useDispatch } from "react-redux";
import { fetchRoles } from "./service";
import { FilterForm } from "./FilterForm";
import { useQuery } from "react-query";
const Features = () => {
  const dispatch = useDispatch();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["roles"],
    queryFn: () => {
      return fetchRoles();
    },
    select: (res) => res?.data,
  });
  const columns = Columns(dispatch, refetch);
  const tableData = Array.isArray(data) ? data : [];
  return (
    <>
      <div>
        <Spin spinning={!!isLoading}>
          <FilterForm refetch={refetch} />
          <Table columns={columns} dataSource={tableData} />
        </Spin>
      </div>
    </>
  );
};

export default Features;

import React, { useEffect, useState } from "react";
import { Table, Spin, Modal } from "antd";
import { Columns } from "./Columns";
import { useSelector, useDispatch } from "react-redux";
import { AllApplications } from "./service";
import { Details } from "./Details";
import { FilterForm } from "./FilterForm";

import {
  setApplicationLoading,
  setApplicationView,
  setEditApplicationModal,
} from "../../actions/ApplicationActions";
import { Img } from "../../components/Img";
export const Applications = () => {
  const [checkAuth, setCheckAuth] = useState(true);

  useEffect(() => {
    getAllApplications();
  }, []);

  const getAllApplications = async () => {
    dispatch(setApplicationLoading(true));
    await AllApplications(dispatch);
    dispatch(setApplicationLoading(false));
  };

  const applicationState = useSelector((state) => state?.applicationState);
  const dispatch = useDispatch();
  const columns = Columns(dispatch);

  const handleClose = () => {
    dispatch(setApplicationLoading(true));
    dispatch(setEditApplicationModal({ visible: false, data: {} }));
    dispatch(setApplicationLoading(false));
  };
  const handleImageClose = () => {
    dispatch(setApplicationLoading(true));
    dispatch(setApplicationView({ visible: false, data: {} }));
    dispatch(setApplicationLoading(false));
  };

  return (
    <>
      <Spin spinning={applicationState?.loading}>
        <FilterForm />
        <Table
          columns={columns}
          dataSource={applicationState?.applications}
          pagination={{
            pageSize: 10,
          }}
          scroll={{ x: 1300, y: 660 }}
        />
        <Modal
          open={applicationState?.editModal?.visible}
          onCancel={handleClose}
          footer={null}
          destroyOnClose={true}
        >
          <Details />
        </Modal>
        {/* <Modal
          open={applicationState?.view?.visible}
          onCancel={handleImageClose}
          footer={null}
          destroyOnClose={true}
        > */}
        {applicationState?.view?.visible && (
          <div style={{ width: "0px", height: "0px" }}>
            <iframe
              style={{ width: "100%" }}
              src={`${process.env.REACT_APP_S3_BUCKET_URL}${applicationState?.view?.data?.resume}`}
            />
          </div>
        )}
        {/* </Modal> */}
      </Spin>
    </>
  );
};

import { Form, Input, Select } from "antd";
import React from "react";
import Button from "../../components/Button";
import { AllApplications, UpdateApplication } from "./service";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import { setApplicationLoading } from "../../actions/ApplicationActions";
export const Details = () => {
  const [form] = Form.useForm();
  const state = useSelector((state) => state?.applicationState);
  const dispatch = useDispatch();
  const handleEdit = async (values) => {
    dispatch(setApplicationLoading(true));
    await UpdateApplication(dispatch, values, state?.editModal?.data?.id);
    await AllApplications(dispatch);
    dispatch(setApplicationLoading(false));
  };

  return (
    <div className="flex flex-col items-center justify-center items-center font-bold text-xl">
      <span>Update Status</span>

      <Form
        onFinish={handleEdit}
        form={form}
        layout="vertical"
        style={{ width: "85%", marginTop: "50px" }}
        initialValues={{
          name: state?.editModal?.data?.client_name,
          company: state?.editModal?.data?.company_name,
          email: state?.editModal?.data?.email,
          phone_number: state?.editModal?.data?.phone_number,
          ticket_type: state?.editModal?.data?.ticket_type,
          status: state?.editModal?.data?.status,
        }}
      >
        <Form.Item name="status" label="Status">
          <Select
            options={[
              { label: "Open", value: "open" },
              {
                label: "Close",
                value: "close",
              },
              {
                label: "Schedule",
                value: "schedule",
              },
            ]}
          ></Select>
        </Form.Item>

        <Form.Item>
          <div>
            <Button
              label="Change Status of Application"
              type="submit"
              htmlType="submit"
            />
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

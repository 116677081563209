import React, { useRef, useState } from 'react'
import Button from "../../components/Button"
import { Col, Form, Input, Modal, Row } from 'antd'
import { useDispatch,useSelector } from 'react-redux'
import { setAddPicturesModal, setPicturesUpdateModal, setPictureLoading, setPicturesViewMOdal } from '../../actions/PictureActions'
import { AddPicturesForm } from './AddPicturesForm'
import { AllPictures } from './service'
import { s3_bucket_url } from '../../constants/constant'
export const FilterForm = () => {

    const dispatch=useDispatch();
    const PicturesState=useSelector((state)=>state?.picturesSate);
    const handleAddPictures = () =>{
        dispatch(setAddPicturesModal(true))
    }
    const handleCloseAddPictures = () =>{
        if(PicturesState?.updatePictureModal){
            dispatch(setPicturesUpdateModal(false));
        }
        if (PicturesState?.viewModal?.visible){
            dispatch(setAddPicturesModal(false));
            dispatch(setPicturesViewMOdal({visible:false,data:{}}))
        }
        else{
            dispatch(setAddPicturesModal(false))

        }
    }

    

    return (
        <>
            <Row>
                <Col
                    span={12}
                    style={{
                        display:"flex",
                        justifyContent:"flex-end"
                    }}
                >
                    <div onClick={handleAddPictures}>
                        <Button
                            label= { 'Add Pictures'}

                        />
                    </div>
                </Col>
            </Row>
            <Modal
                open={PicturesState?.addPictureModal || PicturesState?.updatePictureModal}
                onCancel={handleCloseAddPictures}
                footer={null}
                destroyOnClose={true}
            >
                <AddPicturesForm/>
            </Modal>
            
           {
            
            PicturesState?.viewModal?.visible &&
            <Modal
            open={PicturesState?.viewModal.visible}
            onCancel={handleCloseAddPictures}
            footer={null}
            destroyOnClose={true}
           title="Image Detail"
           >
               <img
               src={`${s3_bucket_url}${PicturesState?.viewModal?.data}`}
               alt="Detail"
               style={{ width: '100%' }}
               />
           </Modal>
           }
        </>
    )
}

import * as actions from "../actions/ActionTypes";


const PicturesReducers = (
    state={
        pictures:[],
        isSearch:false,
        viewModal:{visible:false,data:{}},
        totalPictures:0,
        addPictureModal:false,
        updateModal: {visible:false,data:{}},
        updatePictureModal:false,
        
        loading:false
    }
    , action) =>{
    switch (action.type) {
        case actions.SET_PICTURES_ARRAY:
            return Object.assign({}, state, {
                pictures:action.data
            });
        case actions.SET_PICTURES_VIEW_MODAL:
            return Object.assign({}, state, {
                viewModal:action.data
            });
        case actions.SET_TOTAL_PICTURES:
            return Object.assign({}, state, {
                totalPictures:action.data
            });
        case actions.SET_ADD_PICTURES_MODAL:
            return Object.assign({}, state, {
                addPictureModal:action.data
            });
        case actions.SET_ADD_PICTURES_UPDATE_MODAL:
            return Object.assign({}, state, {
                updatePictureModal:action.data
            });
            case actions.SET_ADD_PICTURES_UPDATE_MODAL_VIEW:
                return Object.assign({}, state, {
                    updateModal:action.data
            });

        case actions.SET_PICTURES_LOADING:
            return Object.assign({}, state, {
                loading:action.data
            });


        default:
            return state;
    }
}
export default PicturesReducers;
import * as actions from "../actions/ActionTypes";

const LeadershipReducer = (
    state={
        leadership:[],
        isSearch:false,
        viewModal:{visible:false,data:{}},
        totalLeaders:0,
        addLeadershipModal:false,
        loading:false
    }
    , action) =>{
    switch (action.type) {
        case actions.SET_LEADERSHIP_ARRAY:
            return Object.assign({}, state, {
                leadership:action.data
            });
        case actions.SET_LEADERSHIP_SEARCH:
            return Object.assign({}, state, {
                isSearch:action.data
            });
        case actions.SET_LEADERSHIP_VIEW_MODAL:
            return Object.assign({}, state, {
                viewModal:action.data
            });
        case actions.SET_TOTAL_LEADERSHIP:
            return Object.assign({}, state, {
                totalLeaders:action.data
            });
        case actions.SET_ADD_LEADERSHIP_MODAL:
            return Object.assign({}, state, {
                addLeadershipModal:action?.data
            });
        case actions.SET_LEADERSHIP_LOADING:
            return Object.assign({}, state, {
                loading:action?.data
            });



        default:
            return state;
    }
}

export default LeadershipReducer;
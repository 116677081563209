import { Form, Input, message, Select, Spin } from "antd";

import React, { useState } from "react";
import Button from "../../components/Button";
import { AllDeveloper, UpdateDeveloper, createDeveloper } from "./service";
import { useDispatch, useSelector } from "react-redux";
import { setDeveloperLoading } from "../../actions/DeveloperActions";
import { s3_bucket_url } from "../../constants/constant";
import { ImCross } from "react-icons/im";

export const AddDeveloperForm = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const state = useSelector((state) => state.developerState);
  const dispatch = useDispatch();
  const [image, setImage] = useState(
    state?.viewModal?.visible ? state?.viewModal?.data?.image : null
  );
  const handleSubmit = async (values) => {
    setLoading(true);
    dispatch(setDeveloperLoading(true));
    await createDeveloper(dispatch, values, image);
    await AllDeveloper(dispatch);
    dispatch(setDeveloperLoading(false));
    setLoading(false);
  };
  const handleEdit = async (values) => {
    setLoading(true);
    dispatch(setDeveloperLoading(true));
    await UpdateDeveloper(dispatch, values, state?.viewModal?.data?.id);
    await AllDeveloper(dispatch);
    dispatch(setDeveloperLoading(false));
    setLoading(false);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const handleDelete = () => {
    setImage(null);
  };

  const colors = [
    { label: "Yellow", value: "#FFCC01" },
    { label: "Green", value: "#56DE7D" },
    { label: "Purple", value: "#A230C3" },
    { label: "Red/Orange", value: "#FF5D52" },
    { label: "Blue", value: "#00B7E9" },
    { label: "Red", value: "#E3285F" },
  ];

  return (
    <Spin spinning={loading}>
      <div className="flex flex-col items-center justify-center items-center  text-xl">
        {!state?.viewModal?.visible && <span>Add New Developer</span>}
        {state?.viewModal?.visible && <span>Edit Developer</span>}

        <Form
          onFinish={state?.viewModal?.visible ? handleEdit : handleSubmit}
          form={form}
          layout="vertical"
          style={{ width: "85%", marginTop: "50px" }}
          initialValues={{
            name: state?.viewModal?.visible ? state?.viewModal?.data?.name : "",
            designation: state?.viewModal?.visible
              ? state?.viewModal?.data?.designation
              : "",
            color: state?.viewModal?.visible
              ? state?.viewModal?.data?.color
              : "",
            linkedIn_URL: state?.viewModal?.visible
              ? state?.viewModal?.data?.linkedin_url
              : "",
            status: state?.viewModal?.visible
              ? state?.viewModal?.data?.status
              : "",
          }}
        >
          <Form.Item
            name="name"
            label="Full Name"
            rules={[
              {
                required: true,
                message: `Name is required`,
              },
              {
                pattern: new RegExp(/^[A-Za-z\s]+$/),
                message: `Alphabet Only`,
              },
            ]}
          >
            <Input placeholder="FULL NAME" />
          </Form.Item>
          <Form.Item
            name="designation"
            label="Designation"
            rules={[
              {
                required: true,
                message: `Designation is required`,
              },
            ]}
          >
            <Input placeholder="Designation" />
          </Form.Item>

          <Form.Item
            name="color"
            label="Color"
            rules={[
              {
                required: true,
                message: `Color is required`,
              },
            ]}
          >
            <Select options={colors} />
          </Form.Item>

          <Form.Item
            name="linkedIn_URL"
            label="LinkedIn URL"
            rules={[
              {
                required: true,
                type: "url",
                message: "This field must be a valid url.",
              },
            ]}
          >
            <Input placeholder="LinkedIn_URL" />
          </Form.Item>

          <Form.Item
            name="status"
            label="Status"
            rules={[
              {
                required: true,
                message: `Status is required`,
              },
            ]}
          >
            <Select
              placeholder="Status"
              options={[
                { label: "Active", value: "active" },
                {
                  label: "Inactive",
                  value: "inactive",
                },
              ]}
            ></Select>
          </Form.Item>

          <Form.Item
            name="image"
            label="Image Upload"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            {/* <input
              name="image"
              type="file"
              accept="image/*"
              onChange={(e) => handleImageChange(e)}
            /> */}
            {typeof image !== "string" && (
              <input
                name="image"
                type="file"
                accept="image/*"
                onChange={(e) => handleImageChange(e)}
              />
            )}
            {image && typeof image === "string" && (
              <div
                style={{
                  position: "relative",
                  width: "100px",
                  height: "100px",
                  borderRadius: "5px",
                  margin: "10px",
                }}
              >
                <ImCross
                  style={{
                    position: "absolute",
                    backgroundColor: "red",
                    borderRadius: "50%",
                    color: "white",
                    padding: "5px",
                    fontSize: "20px",
                    cursor: "pointer",
                    right: "-3px",
                    top: "-3px",
                  }}
                  onClick={handleDelete}
                />
                <img
                  src={`${s3_bucket_url}${image}`}
                  alt="LeaderImage"
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "5px",
                    objectFit: "cover",
                  }}
                />
              </div>
            )}
          </Form.Item>

          <Form.Item>
            <div>
              <Button
                label={
                  state?.viewModal?.visible
                    ? "Update Developer"
                    : "Create Developer"
                }
                type="submit"
                htmlType="submit"
              />
            </div>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  );
};

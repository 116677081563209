import * as actions from "./ActionTypes";

export const setFeaturesArray=(data)=>{
    return {
    type:actions.SET_FEATURES_ARRAY,
    data
}}



export const setRolesLoading=(data)=>{
    return{
    type:actions.SET_ROLES_LOADING,
    data
}}

export const setRolesFeature=(data)=>({
    type:actions.SET_ROLES_FEATURE,
    data
})



export const setAddRolesModal=(data)=>{
    return{
    type:actions.SET_ADD_ROLES_MODAL,
    data
}}


export const setRolesViewMOdal=(data)=>({
    type:actions.SET_ROLES_VIEW_MODAL,
    data
})


export const setAddUserModal=(data)=>({
    type:actions.SET_ADD_ROLES_MODAL,
    data
})




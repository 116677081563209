import * as actions from "./ActionTypes";

export const setJobsArray=(data)=>{  
    return  {
    type:actions.SET_JOB_ARRAY,
    data
}}

export const setJobsSearch=(data)=>({
    type:actions.SET_JOB_SEARCH,
    data
})

export const setJobsViewMOdal=(data)=>({
    type:actions.SET_JOB_VIEW_MODAL,
    data
})

export const setTotalJobs=(data)=>({
    type:actions.SET_TOTAL_JOB,
    data
})

export const setAddJobsModal=(data)=>({
    type:actions.SET_ADD_JOB_MODAL,
    data
})

export const setJobLoading=(data)=>({
    type:actions.SET_JOB_LOADING,
    data
})




import * as actions from "../actions/ActionTypes";

const UsersReducer = (
  state = {
    users: [],
    isSearch: false,
    viewModal: { visible: false, data: {} },
    totalUsers: 0,
    addUserModal: false,
    loading: false,
    userPermissions: [],
    userRoles: [],
    userFeatures: [],
  },
  action
) => {
  switch (action.type) {
    case actions.SET_USERS_ARRAY:
      return Object.assign({}, state, {
        users: action.data,
      });
    case actions.SET_USER_SEARCH:
      return Object.assign({}, state, {
        isSearch: action.data,
      });
    case actions.SET_USER_VIEW_MODAL:
      return Object.assign({}, state, {
        viewModal: action.data,
      });
    case actions.SET_TOTAL_USERS:
      return Object.assign({}, state, {
        totalUsers: action.data,
      });
    case actions.SET_ADD_USER_MODAL:
      return Object.assign({}, state, {
        addUserModal: action?.data,
      });

    default:
      return state;
  }
};

export default UsersReducer;

import { message } from "antd";
import axios from "axios";
import {
    setAddDeveloperModal,
    setTotalDeveloper,
    setDeveloperArray,
} from "../../actions/DeveloperActions";


const accessToken = JSON.parse(localStorage.getItem("userData"));

export const createDeveloper = async (dispatch, values,image) => {

    const payload = {
        name: values.name,
        designation: values.designation,
        color: values.color,
        linkedin_url: values.linkedIn_URL,
        status: values.status,
        file:image,
    };

    const formParams = new FormData();
    formParams.append('name', payload?.name);
    formParams.append('designation', payload?.designation);
    formParams.append('color', payload?.color);
    formParams.append('linkedin_url', payload?.linkedin_url);
    formParams.append('status', payload?.status);
    formParams.append('file', image);

    await axios
        .post(`${process.env.REACT_APP_API_URL}/api/developers/create`, formParams, {
            headers: {
                "ngrok-skip-browser-warning": true,
                Authorization: "Bearer " + accessToken.access_token,
            },
        })
        .then((data) => {
            dispatch(setAddDeveloperModal(false));
            message.success("Developer created successfully");
        })

        .catch((error) => {
            const errorMessage = error?.response?.data?.message
            message.error(`${errorMessage}`);
        });
};

export const AllDeveloper = async (dispatch) => {
    await axios
        .get(`${process.env.REACT_APP_API_URL}/api/developers/all`, {
            headers: {
                "ngrok-skip-browser-warning": true,
                Authorization: "Bearer " + accessToken.access_token,
            },
        })
        .then((data) => {
            dispatch(setDeveloperArray(data?.data?.content));
            dispatch(setTotalDeveloper(data?.data?.count));
        })
        .catch((error) => {
            const errorMessage = error?.response?.data?.message
            message.error(`${errorMessage}`);

        });
};

export const Activate=async (object,flag)=>{
    const payload = {
        status:flag ? "inactive" : "active"
    };
    await axios.patch(`${process.env.REACT_APP_API_URL}/api/developers/${object?.id}`,
        payload,
        {
            headers:{
                "ngrok-skip-browser-warning": true,
                Authorization: "Bearer " + accessToken.access_token,

            }
        }
    )
        .then((response)=>{
            message.success("Developers Updated Successfully")
        })
        .catch((error)=>{
            message.error("Developers Updated Error")
        })



}

export const UpdateDeveloper=async(dispatch,values,id)=>{
    const payload = {
        name: values.name,
        designation: values.designation,
        color: values.color,
        linkedIn_URL: values.linkedIn_URL,
        status: values.status
    };

    await axios.patch(`${process.env.REACT_APP_API_URL}/api/developers/${id}`,
        payload,
        {
            headers:{
                "ngrok-skip-browser-warning": true,
                Authorization: "Bearer " + accessToken.access_token,

            }
        }
    )
        .then((response)=>{
            dispatch(setAddDeveloperModal(false));
            message.success("Developer Updated Successfully")
        })
        .catch((error)=>{
            const errorMessage = error?.response?.data?.message
            message.error(`${errorMessage}`);

        })


}

export const deleteDeveloper=async (dispatch,id) => {
    await axios.delete(`${process.env.REACT_APP_API_URL}/api/developers/${id}`,
        {
            headers:{
                "ngrok-skip-browser-warning": true,
                Authorization: "Bearer " + accessToken.access_token,

            }
        }
    )
        .then((response)=>{
            message.success("Developer Deleted Successfully")
        })
        .catch((error)=>{
            const errorMessage = error?.response?.data?.message
            message.error(`${errorMessage}`);

        })

}

export const filter = async (dispatch,string) => {
    await axios
        .get(`${process.env.REACT_APP_API_URL}/api/developers/all?search=${string}`, {
            headers: {
                "ngrok-skip-browser-warning": true,
                Authorization: "Bearer " + accessToken.access_token,
            },
        })
        .then((data) => {
            dispatch(setDeveloperArray(data?.data?.developer));
            dispatch(setTotalDeveloper(data?.data?.count));
        })
        .catch((error) => {
            const errorMessage = error?.response?.data?.message
            message.error(`${errorMessage}`);
        });
};

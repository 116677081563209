import React, { useCallback, useState } from "react";
import Button from "../../components/Button";
import { Col, Form, Input, Modal, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setAddUserModal, setUserViewMOdal } from "../../actions/UserActions";
import { AddUserForm } from "./AddUserForm";
import useProfileInfo from "../../hooks/UseProfile";
import { permissions_list } from "../../constants/StaticText";
import { setUserQueryParams } from "../../reducers/UserProfile";
export const FilterForm = ({ refetch }) => {
  const [form] = Form.useForm();
  const [search, setSearch] = useState("");
  const { userPermissions } = useProfileInfo();
  const { userQueryParams } = useSelector((state) => state.userProfile);
  const dispatch = useDispatch();
  const userState = useSelector((state) => state?.userState);
  const handleAddUser = () => {
    dispatch(setAddUserModal(true));
  };
  const handleCloseAddUser = () => {
    if (userState?.viewModal?.visible) {
      dispatch(setAddUserModal(false));
      dispatch(setUserViewMOdal({ visible: false, data: {} }));
    } else {
      dispatch(setAddUserModal(false));
    }
  };

  const handleSearch = useCallback(
    (values) => {
      dispatch(
        setUserQueryParams({
          ...userQueryParams,
          search: values?.search,
        })
      );
    },
    [dispatch, userQueryParams]
  );

  const handleReset = useCallback(() => {
    setSearch("");
    dispatch(
      setUserQueryParams({
        ...userQueryParams,
        search: null,
      })
    );
    form.resetFields();
  }, [dispatch, userQueryParams, form]);

  return (
    <>
      <Row>
        <Col
          span={12}
          style={{
            display: "flex",
          }}
        >
          <Form onFinish={handleSearch} form={form} style={{ display: "flex" }}>
            <Form.Item
              name="search"
              rules={[
                {
                  required: false,
                  message: "required field for search",
                },
              ]}
            >
              <Input
                placeholder="Search"
                style={{
                  width: "300px",
                  marginRight: "50px",
                }}
                bordered={true}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <div type="submit">
                <Button label="Search" />
              </div>
            </Form.Item>
          </Form>
          {search && (
            <div onClick={handleReset} style={{ marginLeft: "10px" }}>
              <Button label="Reset" />
            </div>
          )}
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {userPermissions?.includes(permissions_list["CREATE_USERS"]) && (
            <div onClick={handleAddUser}>
              <Button label="Add User" />
            </div>
          )}
        </Col>
      </Row>
      <Modal
        open={userState?.addUserModal}
        onCancel={handleCloseAddUser}
        footer={null}
        destroyOnClose={true}
      >
        <AddUserForm refetch={refetch} />
      </Modal>
    </>
  );
};

import * as actions from "./ActionTypes";

export const setTicketArray=(data)=>{
    return {
    type:actions.SET_TICKETS_ARRAY,
    data
}}


export const setEditTicketModal=(data)=>{
    return{
    type:actions.SET_EDIT_TICKET_MODAL,
    data
}}

export const setTicketLoading=(data)=>{
    return{
    type:actions.SET_TICKET_LOADING,
    data
}}




import {Form, Input} from "antd";

import React, {useState} from "react";
import Button from "../../components/Button";
import { AllPictures, createPictures, updatePictures } from "./service";
import { useDispatch, useSelector } from "react-redux";
import { setPicturesLoading, setPicturesUpdateModal } from "../../actions/PictureActions";

export const AddPicturesForm = () => {
    const [form] = Form.useForm();
    const state=useSelector((state)=>state.picturesSate);
    const dispatch=useDispatch();
    const [image1,setImage1]=useState(null);
    const [image2,setImage2]=useState(null);
    const [image3,setImage3]=useState(null);
    const [backgroundImage,setBackgroundImage]=useState(null);

    const handleSubmit =async (values) => {
        dispatch(setPicturesUpdateModal(false));

        if(state?.updatePictureModal){
            dispatch(setPicturesLoading(true));
            await updatePictures(dispatch,state?.updateModal?.data,values,image1,image2,image3,backgroundImage);
            await AllPictures(dispatch);
            dispatch(setPicturesLoading(false));
        }else{
            dispatch(setPicturesLoading(true));
            await createPictures(dispatch,values,image1,image2,image3,backgroundImage);
            await AllPictures(dispatch);
            dispatch(setPicturesLoading(false));
        }

     

    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    const handleImageChange1=(e)=>{
        const file=e.target.files[0]
        setImage1(file);
    }

    const handleImageChange2=(e)=>{
        const file=e.target.files[0]
        setImage2(file);
    }

    const normFile2 = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    const handleImageChange3=(e)=>{
        const file=e.target.files[0]
        setImage3(file);
    }

    const handleBackgroundImage=(e)=>{
        const file=e.target.files[0]
        setBackgroundImage(file);
    }



    const getLabel = () => {
        if (state?.viewModal?.visible){
            return "Add Picture"
        }
        else if(state?.updatePictureModal){
            return "Update Picture"
        }
        return "Add Picture"
    }


    return (
        <div className="flex flex-col items-center justify-center items-center font-bold text-xl">
            {(!state?.viewModal?.visible && !state?.updatePictureModal)   &&
                <span>Add New Picture</span>}
            {  state?.updatePictureModal &&
                <span>Update Picture</span>}

            <Form
                onFinish={handleSubmit}
                form={form}
                layout="vertical"
                style={{ width: "85%", marginTop: "50px" }}
            >

                <Form.Item
                    name="heading"
                    label="heading"
                    rules={[
                        {
                            required: !state?.updatePictureModal,
                            message: `Heading is required`,
                        },
                    ]}
                >
                    <Input placeholder="Heading" />
                </Form.Item>

                <Form.Item
                    name="detail"
                    label="detail"
                    rules={[
                        {
                            required: state?.updatePictureModal ? false :   true,
                            message: `Detail is required`,
                        },
                    ]}
                >
                    <Input placeholder="Detail" />
                </Form.Item>

                <Form.Item
                    name="image1"
                    label="Image1 Upload"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    rules={[
                        {
                            required: !state?.updatePictureModal,
                            message: `Image1 is required`,
                        },
                    ]}
                >

                    <input
                        name="image"
                        type='file'
                        accept='image/*'
                        onChange={(e)=>handleImageChange1(e)}
                        

                    />

                </Form.Item>

                <Form.Item
                    name="image2"
                    label="Image2 Upload"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    rules={[
                        {
                            required: !state?.updatePictureModal,
                            message: `Image2 is required`,
                        },
                    ]}
                >

                    <input
                        name="image"
                        type='file'
                        accept='image/*'
                        onChange={(e)=>handleImageChange2(e)}

                    />

                </Form.Item>

                <Form.Item
                    name="image3"
                    label="Image3 Upload"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    rules={[
                        {
                            required: state?.updatePictureModal ? false :   true,
                            message: `Image3 is required`,
                        },
                    ]}
                >

                    <input
                        name="image"
                        type='file'
                        accept='image/*'
                        onChange={(e)=>handleImageChange3(e)}

                    />

                </Form.Item>

                <Form.Item
                    name="background-image"
                    label="background Upload"
                    valuePropName="fileList2"
                    getValueFromEvent={normFile2}
                    rules={[
                        {
                            required: state?.updatePictureModal ? false :   true,
                            message: `Background Image is required`,
                        },
                    ]}
                >

                    <input
                        name="backgroundimage"
                        type='file'
                        accept='image/*'
                        onChange={(e)=>handleBackgroundImage(e)}

                    />

                </Form.Item>

                <Form.Item>
                    <div >
                        <Button label= {getLabel()}
                                type="submit" htmlType="submit"
                        />
                    </div>
                </Form.Item>

            </Form>
        </div>
    );
};


import React from "react";
import { Tooltip, Popconfirm, Tag } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  setAddRolesModal,
  setRolesLoading,
  setRolesViewMOdal,
} from "../../actions/RoleActions";
import { deleteRole, fetchRolesAndFeatures } from "./service";
import { permissions_list } from "../../constants/StaticText";
import useProfileInfo from "../../hooks/UseProfile";

export const Columns = (dispatch, refetch) => {
  const { userPermissions } = useProfileInfo();

  return [
    {
      title: "Id",
      key: "1",
      width: 150,
      render: (_, object) => object?.id,
    },
    {
      key: "2",
      title: "Roles",
      width: 150,
      render: (_, object) => object?.name,
    },
    //   {
    //     key:'3',
    //     title:"Features",
    //     width:150,
    //     render:(_,object)=>(
    //         object?.features.map((feat) => {
    //           return (
    //             <Tag key={feat.id}>{feat.feature_name}</Tag>
    //           )
    //         })
    //       )        ,
    // },
    {
      fixed: "right",
      width: 150,
      title: "Actions",
      align: "center",
      render: (_, object, index) => (
        <>
          {userPermissions?.includes(permissions_list["UPDATE_ROLES"]) && (
            <Tooltip title="Detail">
              <EditOutlined
                style={{
                  color: "blue",
                  cursor: "pointer",
                  fontSize: "20px",
                  paddingTop: "5px",
                }}
                onClick={() => {
                  dispatch(setRolesViewMOdal({ visible: true, data: object }));
                  dispatch(setAddRolesModal(true));
                }}
              />
            </Tooltip>
          )}
          &nbsp; &nbsp;
          {userPermissions?.includes(permissions_list["DELETE_ROLES"]) && (
            <Tooltip title="Delete Role">
              <Popconfirm
                style={{ zIndex: 10001 }}
                title="Are you sure to delete this Role?"
                onConfirm={async () => {
                  dispatch(setRolesLoading(true));
                  await deleteRole(dispatch, object?.id);
                  refetch();
                  dispatch(setRolesLoading(false));
                }}
                okText="Yes"
                okType="primary"
                cancelText="No"
              >
                <DeleteOutlined
                  style={{
                    color: "red",
                    cursor: "pointer",
                    fontSize: "20px",
                    paddingTop: "5px",
                  }}
                />
              </Popconfirm>
            </Tooltip>
          )}
          &nbsp; &nbsp;
        </>
      ),
      key: "2",
    },
  ];
};

import * as actions from "../actions/ActionTypes";


const TicketReducers = (
    state={
        tickets:[],
        editModal:{visible:false,data:{}},
        loading:false
    }
    , action) =>{
        switch (action.type) {
            case actions.SET_TICKETS_ARRAY:
              return Object.assign({}, state, {
                tickets:action.data
              });

              case actions.SET_TICKET_LOADING:
                return Object.assign({}, state, {
                loading:action.data
              });
              case actions.SET_EDIT_TICKET_MODAL:
                return Object.assign({}, state, {
                editModal:action.data
              });


        
              default:
                return state;
            }
}
export default TicketReducers;
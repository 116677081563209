import React, { useEffect } from "react";
import { FilterForm } from "./FilterForm";
import { Spin, Table } from "antd";
import { Columns } from "./Columns";
import { useDispatch, useSelector } from "react-redux";
import { setJobLoading } from "../../actions/JobActions";
import { AllJobs } from "./service";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
export const Jobs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getAllJobs();
  }, []);
  const getAllJobs = async () => {
    dispatch(setJobLoading(true));
    await AllJobs(dispatch);
    dispatch(setJobLoading(false));
  };
  const jobData = useSelector((state) => state.jobState);
  const columns = Columns(dispatch, navigate);
  return (
    <>
      <Spin spinning={jobData?.loading}>
        <FilterForm />
        <Table
          columns={columns}
          dataSource={jobData?.jobs}
          pagination={{
            pageSize: 10,
          }}
          scroll={{ x: 1300, y: 660 }}
        />
      </Spin>
    </>
  );
};

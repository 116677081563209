// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.ant-select-single{
    height:50px !important;
}

.ant-btn.ant-btn-sm{
    border-color: blue !important;
    color:black !important;
}                          

`, "",{"version":3,"sources":["webpack://./src/screens/JobApplications/style.css"],"names":[],"mappings":";AACA;IACI,sBAAsB;AAC1B;;AAEA;IACI,6BAA6B;IAC7B,sBAAsB;AAC1B","sourcesContent":["\n.ant-select-single{\n    height:50px !important;\n}\n\n.ant-btn.ant-btn-sm{\n    border-color: blue !important;\n    color:black !important;\n}                          \n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { message } from "antd";
import axios from "axios";
import {
  setAddPicturesModal,
  setPicturesUpdateModal,
  setPicturesArray,
  setTotalPictures,
} from "../../actions/PictureActions";

const accessToken = JSON.parse(localStorage.getItem("userData"));

export const createPictures = async (
  dispatch,
  values,
  image1,
  image2,
  image3,
  backgroundImage
) => {
  const payload = {
    heading: values.heading,
    detail: values.detail,
    file1: image1,
    file2: image2,
    file3: image3,
    backgroundFile: backgroundImage,
  };

  const formParams = new FormData();
  formParams.append("heading", payload?.heading);
  formParams.append("detail", payload?.detail);
  formParams.append("file1", payload.file1);
  formParams.append("file2", payload.file2);
  formParams.append("file3", payload.file3);
  formParams.append("backgroundFile", backgroundImage);

  await axios
    .post(`${process.env.REACT_APP_API_URL}/api/pictures/create`, formParams, {
      headers: {
        "ngrok-skip-browser-warning": true,
        Authorization: "Bearer " + accessToken.access_token,
      },
    })
    .then((data) => {
      dispatch(setAddPicturesModal(false));
      message.success("Picture Added successfully");
    })

    .catch((error) => {
      const errorMessage = error?.response?.data?.message;
      message.error(`${errorMessage}`);
    });
};

export const updatePictures = async (
  dispatch,
  id,
  values,
  image1,
  image2,
  image3,
  backgroundImage
) => {
  const payload = {
    heading: values.heading,
    detail: values.detail,
    file1: image1,
    file2: image2,
    file3: image3,
    backgroundFile: backgroundImage,
  };
  const formParams = new FormData();
  if (payload?.heading) {
    formParams.append("heading", payload?.heading);
  }
  if (payload.detail) {
    formParams.append("detail", payload?.detail);
  }
  if (payload.file1) {
    formParams.append("file1", payload.file1);
  }
  if (payload.file2) {
    formParams.append("file2", payload.file2);
  }
  if (payload.file3) {
    formParams.append("file3", payload.file3);
  }
  if (payload.backgroundFile) {
    formParams.append("backgroundFile", backgroundImage);
  }

  await axios
    .patch(`${process.env.REACT_APP_API_URL}/api/pictures/${id}`, formParams, {
      headers: {
        "ngrok-skip-browser-warning": true,
        Authorization: "Bearer " + accessToken.access_token,
      },
    })
    .then((data) => {
      dispatch(setPicturesUpdateModal(false));
      message.success("Picture Updated successfully");
    })

    .catch((error) => {
      dispatch(setPicturesUpdateModal(false));
      const errorMessage = error?.response?.data?.message;
      message.error(`${errorMessage}`);
    });
};

export const AllPictures = async (dispatch) => {
  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/pictures/all`, {
      headers: {
        "ngrok-skip-browser-warning": true,
        Authorization: "Bearer " + accessToken.access_token,
      },
    })
    .then((data) => {
      dispatch(setPicturesArray(data?.data));
      dispatch(setTotalPictures(data?.data?.count));
    })
    .catch((error) => {
      const errorMessage = error?.response?.data?.message;
      message.error(`${errorMessage}`);
    });
};

export const deletePictures = async (dispatch, id) => {
  await axios
    .delete(`${process.env.REACT_APP_API_URL}/api/pictures/${id}`, {
      headers: {
        "ngrok-skip-browser-warning": true,
        Authorization: "Bearer " + accessToken.access_token,
      },
    })
    .then((response) => {
      message.success("Picture Deleted Successfully");
    })
    .catch((error) => {
      const errorMessage = error?.response?.data?.message;
      message.error(`${errorMessage}`);
    });
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-input::placeholder {
  color: black;
  font-size: 16px;
}

.login-input {
  border-left: 0px !important;
  border-right: 0px !important;
  border-bottom-color: black;
  border-bottom-width: 1px;
  border-top: 0px;
  color: black;
}

.eyeIcon {
  position: relative;
  background-color: black;
}
.eyeIcon-inner {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 18px;
  cursor: pointer;
  z-index: 100;
}
`, "",{"version":3,"sources":["webpack://./src/components/TextInput/styles.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EAEE,2BAA2B;EAC3B,4BAA4B;EAC5B,0BAA0B;EAC1B,wBAAwB;EACxB,eAAe;EACf,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,uBAAuB;AACzB;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,eAAe;EACf,eAAe;EACf,YAAY;AACd","sourcesContent":[".my-input::placeholder {\n  color: black;\n  font-size: 16px;\n}\n\n.login-input {\n  border-top: 0px !important;\n  border-left: 0px !important;\n  border-right: 0px !important;\n  border-bottom-color: black;\n  border-bottom-width: 1px;\n  border-top: 0px;\n  color: black;\n}\n\n.eyeIcon {\n  position: relative;\n  background-color: black;\n}\n.eyeIcon-inner {\n  position: absolute;\n  top: 15px;\n  right: 15px;\n  font-size: 18px;\n  cursor: pointer;\n  z-index: 100;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

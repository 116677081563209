import React, { useEffect, useState } from "react";
import { Table, Spin, Modal } from "antd";
import { Columns } from "./Columns";
import { useSelector, useDispatch } from "react-redux";
import {
  setEditTicketModal,
  setTicketLoading,
} from "../../actions/TicketsAction";
import { AllTickets } from "./service";
import { Details } from "./Details";
import { FilterForm } from "./FilterForm";

export const Tickets = () => {
  const [checkAuth, setCheckAuth] = useState(true);

  useEffect(() => {
    getAllTickets();
  }, []);

  const getAllTickets = async () => {
    dispatch(setTicketLoading(true));
    await AllTickets(dispatch);
    dispatch(setTicketLoading(false));
  };

  const ticketState = useSelector((state) => state?.ticketState);
  const dispatch = useDispatch();
  const columns = Columns(dispatch);

  const handleClose = () => {
    dispatch(setTicketLoading(true));
    dispatch(setEditTicketModal({ visible: false, data: {} }));
    dispatch(setTicketLoading(false));
  };
  return (
    <>
      <Spin spinning={ticketState?.loading}>
        <FilterForm />
        <Table
          columns={columns}
          dataSource={ticketState?.tickets}
          pagination={{
            pageSize: 10,
          }}
          scroll={{ x: 1300, y: 660 }}
        />
        <Modal
          open={ticketState?.editModal?.visible}
          onCancel={handleClose}
          footer={null}
          destroyOnClose={true}
        >
          <Details />
        </Modal>
      </Spin>
    </>
  );
};
